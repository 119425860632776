body.rtl {
    text-align: right;
    direction: rtl;
}

.rtl {

    dd {
        margin-left: auto;
        margin-right: 0;
    }

    caption {
        text-align: right;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0;
        margin-left: 0.5rem;
    }

    .alert-dismissible {
        padding-right: 1.25rem;
        padding-left: 3.8125rem;

        .close {
            right: auto;
            left: 0;
        }
    }

    .breadcrumb-item+.breadcrumb-item {
        padding-left: 0;
        padding-right: 0.5rem;

        &::before {
            padding-right: 0;
            padding-left: 0.5rem;
        }
    }

    .breadcrumb-5 a {
        float: right;

        &:first-child {
            padding-left: 10px;
            padding-right: 46px;
            border-radius: 0 5px 5px 0;

            &:before {
                left: 0;
                right: 14px;
            }
        }

        &:last-child {
            padding-right: 39px;
            padding-left: 20px;
        }

        &:after {
            right: auto;
            left: -18px;
            transform: scale(0.707) rotate(225deg);
        }
    }

    .breadcrumb-4 {

        ol,
        li {
            text-align: left;
        }
    }

    .btn-group>.btn:not(:first-child),
    .sp-container .btn-group>button:not(:first-child) {
        margin-left: auto;
        margin-right: -1px;
    }

    .btn-group> {
        .btn-group:not(:first-child) {
            margin-left: auto;
            margin-right: -1px;
        }

        .btn:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle),
    .btn-group>.btn-group:not(:last-child)>.btn,
    .sp-container .btn-group>.btn-group:not(:last-child)>button {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-group>.btn:not(:first-child),
    .sp-container .btn-group>button:not(:first-child),
    .btn-group>.btn-group:not(:first-child)>.btn,
    .sp-container .btn-group>.btn-group:not(:first-child)>button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .btn-with-icon i {
        margin-right: auto;
        margin-left: 5px;
    }

    .btn-icon-list {

        .btn+.btn,
        .sp-container button+.btn {
            margin-left: 0;
            margin-right: 5px;
        }
    }

    .sp-container .btn-icon-list button+.btn,
    .btn-icon-list .sp-container .btn+button,
    .sp-container .btn-icon-list .btn+button,
    .btn-icon-list .sp-container button+button,
    .sp-container .btn-icon-list button+button {
        margin-left: 0;
        margin-right: 5px;
    }

    .btn-list> {

        .btn:not(:last-child),
        .dropdown:not(:last-child) {
            margin-right: 0;
            margin-left: 0.5rem;
        }
    }

    .card-link+.card-link {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

    @media (min-width: 576px) {
        .card-group>.card {
            +.card {
                margin-left: auto;
                margin-right: 0;
                border-left: 1px solid $border;
                border-right: 0;
            }

            &:not(:last-child) {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-right-radius: 3px;
                    border-top-left-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:first-child) {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 3px;
                }
            }
        }
    }

    .card-item .card-item-icon {
        right: auto;
        left: 20px;
    }

    .card-options {
        margin-right: auto;
        margin-left: -0.5rem;

        a:not(.btn) {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .card-blog-overlay:before,
    .card-blog-overlay1:before,
    .card-blog-overlay2:before {
        right: auto;
        left: 0;
    }

    @media (max-width: 575px) {
        .card-box img {
            left: auto !important;
            right: -14px !important;
        }
    }

    .carousel-item {
        float: right;
        margin-right: auto;
        margin-left: -100%;
    }

    .carousel-control-prev {
        left: auto;
        right: 0;
        transform: rotate(180deg);
    }

    .carousel-control-next {
        right: auto;
        left: 0;
        transform: rotate(180deg);
    }

    .carousel-indicators {
        padding-left: inherit;
        padding-right: 0;
    }

    #thumbcarousel {
        .carousel-control-prev {
            left: auto;
            right: 0;
        }

        .carousel-control-next {
            right: auto;
            left: 0;
        }
    }

    .owl-nav {
        .owl-prev {
            right: -25px;
            left: -1.5em;
        }

        .owl-next {
            right: auto;
            left: -25px;
        }
    }

    .dropdown-toggle:empty::after {
        margin-left: auto;
        margin-right: 0;
    }

    .dropdown-menu-left {
        left: auto;
        right: 0;
    }

    .dropdown-menu-right {
        left: 0;
        right: auto;
    }

    @media (min-width: 576px) {
        .dropdown-menu-sm-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-sm-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 768px) {
        .dropdown-menu-md-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-md-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 992px) {
        .dropdown-menu-lg-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-lg-right {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: 1200px) {
        .dropdown-menu-xl-left {
            left: auto;
            right: 0;
        }

        .dropdown-menu-xl-right {
            left: 0;
            right: auto;
        }
    }

    .dropup .dropdown-toggle {
        &::after {
            margin-left: auto;
            margin-right: 0.255em;
        }

        &:empty::after {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .dropright {
        .dropdown-menu {
            left: auto;
            right: 100%;
            margin-left: auto;
            margin-right: 0.125rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: auto;
                margin-right: 0.255em;
                border-right: 0.3em solid;
                border-left: 0;
            }

            &:empty::after {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .dropleft {
        .dropdown-menu {
            left: 100%;
            right: auto;
            margin-right: auto;
            margin-left: 0.125rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: auto;
                margin-right: 0.255em;
            }

            &::before {
                margin-right: auto;
                margin-left: 0.255em;
                border-right: 0;
                border-left: 0.3em solid;
            }

            &:empty::after {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .dropdown-menu {
        .main-header-center .search-btn {
            padding: 0.375rem 0.7rem 0.4rem 0.85rem;
        }

        .main-form-search {

            .btn,
            .sp-container button {
                padding: 0.375rem 0.7rem 0.4rem 0.85rem;
            }
        }

        .sp-container .main-form-search button {
            padding: 0.375rem 0.7rem 0.4rem 0.85rem;
        }
    }

    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropright .dropdown-toggle-split::after {
        margin-left: auto;
        margin-right: 0;
    }

    .dropleft .dropdown-toggle-split::before {
        margin-right: auto;
        margin-left: 0;
    }

    .dropdown-menu {
        // left: 0 !important;
        // right: auto !important;
        float: right;
        text-align: right;

        &[data-popper-placement^=top],
        &[data-popper-placement^=right],
        &[data-popper-placement^=bottom],
        &[data-popper-placement^=left] {
            right: 0;
            left: auto;
        }
    }

    .form-switch {
        padding-left: 2.5em;
        padding-right: inherit;
    }

    .form-check {
        padding-left: inherit;
        padding-right: 1.25rem;
    }

    .form-check-input {
        margin-left: auto;
        margin-right: -1.25rem;
    }

    .form-check-inline {
        padding-left: inherit;
        padding-right: 0;
        margin-right: auto;
        margin-left: 0.75rem;

        .form-check-input {
            margin-right: 0;
            margin-left: 0.3125rem;
        }
    }

    @media (min-width: 576px) {
        .form-inline {
            .form-check {
                padding-left: inherit;
                padding-right: 0;
            }

            .form-check-input {
                margin-right: 0;
                margin-left: 0.25rem;
            }
        }
    }

    .file-browser .btn-default {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }

    .was-validated .form-control:valid,
    .form-control.is-valid,
    input.form-control.parsley-success,
    textarea.form-control.parsley-success {
        padding-right: 0.75rem;
        padding-left: calc(1.5em + 0.75rem);
        background-position: center left calc(0.375em + 0.1875rem);
    }

    .was-validated textarea.form-control:valid {
        padding-right: 0.75rem;
        padding-left: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
    }

    textarea.form-control {

        &.is-valid,
        &.parsley-success {
            padding-right: 0.75rem;
            padding-left: calc(1.5em + 0.75rem);
            background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
        }
    }

    .was-validated .custom-select:valid,
    .custom-select.is-valid,
    input.custom-select.parsley-success,
    textarea.custom-select.parsley-success {
        padding-right: 0.75rem;
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    input.form-control.parsley-error,
    textarea.form-control.parsley-error {
        padding-right: 0.75rem;
        padding-left: calc(1.5em + 0.75rem);
        background-position: center left calc(0.375em + 0.1875rem);
    }

    .was-validated textarea.form-control:invalid {
        padding-right: 0.75rem;
        padding-left: calc(1.5em + 0.75rem);
        background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
    }

    textarea.form-control {

        &.is-invalid,
        &.parsley-error {
            padding-right: 0.75rem;
            padding-left: calc(1.5em + 0.75rem);
            background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
        }
    }

    .was-validated .custom-select:invalid,
    .custom-select.is-invalid,
    input.custom-select.parsley-error,
    textarea.custom-select.parsley-error {
        padding-right: 0.75rem;
        padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    }

    .input-group> {
        .form-control+ {

            .form-control,
            .custom-select,
            .custom-file {
                margin-left: auto;
                margin-right: -1px;
            }
        }

        .form-control-plaintext+ {

            .form-control,
            .custom-select,
            .custom-file {
                margin-left: auto;
                margin-right: -1px;
            }
        }

        .custom-select+ {

            .form-control,
            .custom-select,
            .custom-file {
                margin-left: auto;
                margin-right: -1px;
            }
        }

        .custom-file+ {

            .form-control,
            .custom-select,
            .custom-file {
                margin-left: auto;
                margin-right: -1px;
            }
        }

        .form-control:not(:last-child),
        .custom-select:not(:last-child) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .form-control:not(:first-child),
        .custom-select:not(:first-child) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .custom-file {
            &:not(:last-child) .custom-file-label {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &::after {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:first-child) .custom-file-label {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .input-group-prepend {

        .btn+.btn,
        .sp-container button+.btn {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-prepend button+.btn,
    .input-group-prepend .sp-container .btn+button,
    .sp-container .input-group-prepend .btn+button,
    .input-group-prepend .sp-container button+button,
    .sp-container .input-group-prepend button+button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-prepend {

        .btn+.input-group-text,
        .sp-container button+.input-group-text {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-prepend button+.input-group-text {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-prepend {
        .input-group-text+ {

            .input-group-text,
            .btn {
                margin-left: auto;
                margin-right: -1px;
            }
        }

        .sp-container .input-group-text+button {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-prepend .input-group-text+button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-append {

        .btn+.btn,
        .sp-container button+.btn {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-append button+.btn,
    .input-group-append .sp-container .btn+button,
    .sp-container .input-group-append .btn+button,
    .input-group-append .sp-container button+button,
    .sp-container .input-group-append button+button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-append {

        .btn+.input-group-text,
        .sp-container button+.input-group-text {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-append button+.input-group-text {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-append {
        .input-group-text+ {

            .input-group-text,
            .btn {
                margin-left: auto;
                margin-right: -1px;
            }
        }

        .sp-container .input-group-text+button {
            margin-left: auto;
            margin-right: -1px;
        }
    }

    .sp-container .input-group-append .input-group-text+button {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-prepend {
        margin-right: auto;
        margin-left: -1px;
    }

    .input-group-append {
        margin-left: auto;
        margin-right: -1px;
    }

    .input-group-lg>.custom-select,
    .input-group-sm>.custom-select {
        padding-right: inherit;
        padding-left: 1.75rem;
    }

    .input-group>.input-group-prepend>.btn,
    .sp-container .input-group>.input-group-prepend>button {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group> {

        .input-group-prepend>.input-group-text,
        .input-group-append:not(:last-child)>.btn {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sp-container .input-group>.input-group-append:not(:last-child)>button {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>.input-group-append {

        &:not(:last-child)>.input-group-text,
        &:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>.input-group-append {
        &:last-child>.input-group-text:not(:last-child) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        >.btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .sp-container .input-group>.input-group-append>button {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group> {

        .input-group-append>.input-group-text,
        .input-group-prepend:not(:first-child)>.btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .sp-container .input-group>.input-group-prepend:not(:first-child)>button {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group>.input-group-prepend {

        &:not(:first-child)>.input-group-text,
        &:first-child>.btn:not(:first-child) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
    .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .close {
        float: left;
    }

    .modal,
    .modal-backdrop {
        left: auto;
        right: 0;
    }

    .modal-header .close {
        margin: -1rem auto -1rem -1rem;
    }

    .modal-footer> {
        :not(:first-child) {
            margin-left: inherit;
            margin-right: 0.25rem;
        }

        :not(:last-child) {
            margin-right: inherit;
            margin-left: 0.25rem;
        }
    }

    @media (min-width: 768px) {
        .main-nav .nav-link+.nav-link {
            padding-left: inherit;
            padding-right: 15px;
            margin-left: 0;
            margin-right: 15px;
        }
    }

    .main-nav-column .nav-link {
        i {
            margin-right: 0;
            margin-left: 12px;
        }

        span {
            margin-left: 0;
            margin-right: auto;
        }

        &.active::before {
            left: auto;
            right: -28px;
        }
    }

    @media (min-width: 769px) {
        .main-nav-line .nav-link+.nav-link {
            margin-left: 0;
            margin-right: 30px;
        }
    }

    @media (max-width: 768px) {
        .main-nav-line .nav-link+.nav-link {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media (min-width: 992px) {
        .main-nav-line-chat {
            padding-left: inherit;
            padding-right: 0;
        }

        .nav.main-nav-line-chat {
            padding-left: inherit;
            padding-right: 20px;
        }
    }

    .pagination {
        padding-left: 0;
        padding-right: 7px;
    }

    .pagination-circled .page-item+.page-item {
        margin-left: 0;
        margin-right: 5px;
    }

    .pagination-radius li {

        a,
        span {
            margin-left: 0;
            margin-right: 4px;
        }
    }

    .popover {
        left: auto;
        right: 0;
        text-align: right;
    }

    .popover-head-primary {

        &.bs-popover-left .arrow,
        &.bs-popover-auto[data-popper-placement^=left] .arrow {
            right: auto;
            left: -7px;
        }

        &.bs-popover-right .arrow,
        &.bs-popover-auto[data-popper-placement^=right] .arrow {
            left: auto;
            right: -7px;
        }
    }

    .popover-head-secondary {

        &.bs-popover-left .arrow,
        &.bs-popover-auto[data-popper-placement^=left] .arrow {
            right: auto;
            left: -7px;
        }

        &.bs-popover-right .arrow,
        &.bs-popover-auto[data-popper-placement^=right] .arrow {
            left: auto;
            right: -7px;
        }
    }

    .popover-static-demo {

        .bs-popover-top .arrow,
        .bs-popover-auto[data-popper-placement^=top] .arrow,
        .bs-popover-bottom .arrow,
        .bs-popover-auto[data-popper-placement^=bottom] .arrow {
            left: auto;
            right: 50%;
            margin-left: auto;
            margin-right: -5px;
        }
    }

    .bs-popover-right,
    .bs-popover-auto[data-popper-placement^=right] {
        margin-left: auto;
        margin-right: 0.5rem;
    }

    .bs-popover-right>.arrow,
    .bs-popover-auto[data-popper-placement^=right]>.arrow {
        left: auto;
        right: calc((0.5rem + 1px) * -1);
    }

    .bs-popover-right>.arrow::before,
    .bs-popover-auto[data-popper-placement^=right]>.arrow::before {
        left: auto;
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-right-color: transparent;
        border-left-color: #eaedf7;
    }

    .bs-popover-right>.arrow::after,
    .bs-popover-auto[data-popper-placement^=right]>.arrow::after {
        left: auto;
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-right-color: transparent;
        border-left-color: $white;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -0.5rem;
    }

    .bs-popover-left,
    .bs-popover-auto[data-popper-placement^=left] {
        margin-right: auto;
        margin-left: 0.5rem;
    }

    .bs-popover-left>.arrow,
    .bs-popover-auto[data-popper-placement^=left]>.arrow {
        right: auto;
        left: calc((0.5rem + 1px) * -1);
    }

    .bs-popover-left>.arrow::before,
    .bs-popover-auto[data-popper-placement^=left]>.arrow::before {
        right: auto;
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-left-color: transparent;
        border-right-color: #eaedf7;
    }

    .bs-popover-left>.arrow::after,
    .bs-popover-auto[data-popper-placement^=left]>.arrow::after {
        right: auto;
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-left-color: transparent;
        border-right-color: $white;
    }

    .dataTables_wrapper .dataTables_filter input {
        margin-left: auto;
        margin-right: 0;
    }

    .table.dataTable {

        th,
        td {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    div.dataTables_wrapper div.dataTables_length select {
        margin-right: auto;
        margin-left: 10px;
    }

    .tooltip {
        text-align: right;
    }

    // .bs-tooltip-right .arrow,
    // .bs-tooltip-auto[data-popper-placement^=right] .arrow {
    //     left: auto;
    //     right: 0;
    // }

    // .bs-tooltip-right .arrow::before,
    // .bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    //     right: auto;
    //     left: 0;
    //     border-width: 0.4rem 0 0.4rem 0.4rem;
    //     border-right-color: transparent;
    //     border-left-color: #000000;
    // }

    // .bs-tooltip-left .arrow,
    // .bs-tooltip-auto[data-popper-placement^=left] .arrow {
    //     right: auto;
    //     left: 0;
    // }

    // .bs-tooltip-left .arrow::before,
    // .bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    //     left: auto;
    //     right: 0;
    //     border-width: 0.4rem 0.4rem 0.4rem 0;
    //     border-left-color: transparent;
    //     border-right-color: #000000;
    // }

    .tooltip-static-demo {

        .bs-tooltip-top .arrow,
        .bs-tooltip-auto[data-popper-placement^=top] .arrow,
        .bs-tooltip-bottom .arrow,
        .bs-tooltip-auto[data-popper-placement^=bottom] .arrow {
            right: 50%;
            left: auto;
            margin-right: -2px;
            margin-left: auto;
        }
    }

    @media (max-width: 991.98px) {
        .main-content-left-show .main-content-left {
            left: auto;
            right: 0;
            border-right: 0;
            border-left: 1px solid $border;
        }
    }

    .main-content-right {
        padding-left: inherit;
        padding-right: 25px;
        margin-right: 25px;
        margin-left: auto;
    }

    @media (max-width: 991.98px) {
        .main-header-menu-show .main-header-menu {
            border-right: 0;
            border-left: 1px solid rgba(28, 39, 60, 0.08);
        }
    }

    .main-logo i {
        margin-right: auto;
        margin-left: 8px;
    }

    .main-header-menu-icon {
        margin-right: auto;
        margin-left: 10px;

        span {

            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    @media (min-width: 992px) {
        .main-header-menu-icon {
            margin-right: auto;
            margin-left: 25px;
        }
    }

    .main-header-menu {
        left: auto;
        right: 0;

        .nav-item {
            &::before {
                margin-left: auto;
                margin-right: 25px;
                border-left: 0;
                border-right: 2px solid rgba(28, 39, 60, 0.12);
            }

            >.nav-link {
                &.with-sub::after {
                    margin-right: auto;
                    margin-left: 0;
                }

                .typcn {
                    margin-right: auto;
                    margin-left: 15px;
                }
            }

            &.active>.nav-link::before {
                left: auto;
                right: -20px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-header-menu {
            border-right: 1px solid $border;
            border-left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item+.nav-item {
            margin-left: auto;
            margin-right: 30px;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item>.nav-link.with-sub::after {
            margin-left: auto;
            margin-right: 5px;
        }
    }

    @media (min-width: 992px) {
        .main-header-menu .nav-item>.nav-link .typcn {
            margin-right: auto;
            margin-left: 7px;
        }
    }

    .main-header-center {
        margin: 0 0.8rem 0 0;

        .search-btn {
            padding: 0.375rem 0.7rem 0.4rem 0.85rem;
        }
    }

    .main-header-right {
        .btn-social+.btn-social {
            margin-right: 10px;
            margin-left: auto;
        }

        .btn-buy {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    .main-header-search-link {
        margin-right: auto;
        margin-left: 15px;
    }

    .main-header-message>a.new::before {
        right: auto;
        left: 2px;
    }

    .main-header-notification {
        >a.new::before {
            right: auto;
            left: 2px;
        }

        .dropdown-menu {
            left: -10px !important;
            right: auto !important;
        }
    }

    .main-header-message .dropdown-menu {
        left: -10px !important;
        right: auto;
    }

    .main-menu-sub {
        margin-left: auto;
        margin-right: 32px;
    }

    @media (min-width: 992px) {
        .main-menu-sub {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .container>div+div {
            border-right: 1px solid rgba(28, 39, 60, 0.05);
            border-left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-menu-sub-mega .nav+.nav {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    @media (min-width: 1200px) {
        .main-menu-sub-mega .nav+.nav {
            margin-left: auto;
            margin-right: 25px;
        }
    }

    .main-header-notification.show .dropdown-menu::before,
    .main-header-message.show .dropdown-menu::before,
    .main-profile-menu.show .dropdown-menu::before {
        right: auto;
        left: 28px;
    }

    .header-search {
        &.show .dropdown-menu::before {
            right: auto;
            left: 28px;
            border-right: 1px solid #f0f2f8;
            border-left: 0;
        }

        .dropdown-menu {
            left: -10px;
            right: auto;
        }
    }

    .main-form-search {
        .form-control {
            padding-right: 2.6rem;
            padding-left: inherit;
        }

        .btn,
        .sp-container button {
            left: auto;
            right: 8px;
            padding-left: inherit;
            padding-right: 16px;
        }
    }

    .sp-container .main-form-search button {
        left: auto;
        right: 8px;
        padding-left: inherit;
        padding-right: 16px;
    }

    .main-message-list .media {
        padding: 10px 15px 0px 10px;
    }

    .main-header-flags {
        .dropdown-menu {
            left: auto;
            right: -10px;
        }

        &.show .dropdown-menu::before {
            left: auto;
            right: 28px;
            border-left: 0;
            border-right: 1px solid #f0f2f8;
        }
    }

    .main-flags-list .media {
        padding: 10px 15px 0px 10px;
    }

    .hor-header.hor-top-header .nav-link-badge {
        left: auto;
        right: 11px;
    }

    @media (max-width: 991px) {
        .hor-header.hor-top-header .main-logo {
            left: auto;
            right: 0px;
            margin-right: 0;
            margin-left: auto;
        }
    }

    .main-header-right .nav-link .badge {
        right: auto;
        left: 4px;
    }

    .sidebar-left {
        right: 0;
        left: auto;
    }

    .sidebar-right,
    .sidebar-profile {
        right: auto;
        left: 0;
    }

    .row .sidebar {
        &.sidebar-left.sidebar-xs-show {
            left: auto;
            right: 0;
        }

        &.sidebar-right.sidebar-xs-show,
        &.sidebar-profile.sidebar-xs-show {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 768px) {
        .row .sidebar {
            &.sidebar-left.sidebar-sm-show {
                left: auto;
                right: 0;
            }

            &.sidebar-right.sidebar-sm-show,
            &.sidebar-profile.sidebar-sm-show {
                right: auto;
                left: 0;
            }
        }
    }

    @media (min-width: 992px) {
        .row .sidebar {
            &.sidebar-left.sidebar-md-show {
                left: auto;
                right: 0;
            }

            &.sidebar-right.sidebar-md-show,
            &.sidebar-profile.sidebar-md-show {
                right: auto;
                left: 0;
            }
        }
    }

    @media (min-width: 1170px) {
        .row .sidebar {
            &.sidebar-left.sidebar-lg-show {
                left: auto;
                right: 0;
            }

            &.sidebar-right.sidebar-lg-show,
            &.sidebar-profile.sidebar-lg-show {
                right: auto;
                left: 0;
            }
        }
    }

    .sidebar-icon {
        right: auto;
        left: 25px;
    }

    .main-profile-menu {
        .dropdown-menu {
            right: auto;
            left: -10px;
        }

        .dropdown-item i {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .main-iconbar {
        left: auto;
        right: 0;
        border-right: 0;
        border-left: 1px solid $border;
    }

    .main-iconbar-logo::after {
        left: auto;
        right: 20px;
    }

    .main-iconbar-aside {
        border-right: 0;
        border-left: 1px solid $border;
        left: auto;
        right: 64px;
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside {
            border-right: 1px solid $border;
            border-left: 0;
        }
    }

    @media (min-width: 1200px) {
        .main-iconbar-aside.show+.main-content {
            margin-right: 294px;
            margin-left: auto;
        }
    }

    .main-iconbar-header {
        padding-right: 0;
        padding-left: inherit;
    }

    @media (min-width: 1200px) {
        .main-iconbar-toggle-menu::before {
            left: auto;
            right: -1px;
            border-left: 0;
            border-right: 2px solid #000000;
        }
    }

    .main-iconbar-body {
        .nav-link {
            i {
                margin-right: auto;
                margin-left: 10px;
            }

            &.with-sub::after {
                margin-left: 0;
                margin-right: auto;
            }
        }

        .nav-sub .nav-sub-item {
            margin-right: 15px;
            margin-left: auto;
        }
    }

    @media (min-width: 992px) {
        .main-iconbar-body .nav-link.with-sub::after {
            margin-right: 4px;
            margin-left: auto;
        }
    }

    .main-iconbar-aside-primary {
        border-right: 0;
        border-left: 1px solid $border;

        .main-iconbar-body .nav {
            padding-right: 0;
            padding-left: inherit;
            border-left-width: 1px;
            border-right-width: 0;
        }
    }

    @media (min-width: 992px) {
        .main-iconbar-aside-primary.show+.main-content {
            margin-left: auto;
            margin-right: 64px;
        }
    }

    .icons-list {
        margin: 0 0 -1px -1px;
    }

    @media (min-width: 992px) {
        &.horizontalmenu {
            .hor-menu {
                .nav .nav-item {
                    padding-right: 0;
                    padding-left: 15px;
                }

                li.nav-item .nav-link {
                    padding-right: inherit;
                    padding-left: 10px;
                }

                .nav-link .hor-icon {
                    margin-right: 10px;
                    margin-left: 5px;
                }
            }

            .main-navbar .nav-sub-item {
                margin-left: auto;
                margin-right: 0;
            }

            .menu-nav .nav-item ul.nav-sub::before {
                left: auto;
                right: 36%;
            }

            .hor-menu .menu-nav .nav-sub {
                left: auto;
                right: inherit;
            }
        }

        .main-navbar {
            .nav-sub-item>.sub-nav-sub {
                left: auto;
                right: 158px;
                padding: 0 12px 0 0;
            }

            .nav-sub-link {
                margin-left: auto;
                margin-right: 18px;
                padding-left: inherit;
                padding-right: 8px;

                &.sub-with-sub::after {
                    margin-right: auto;
                    margin-left: 3px;
                }
            }
        }
    }

    .ltr {
        #slide-right {
            left: 20px;
            right: auto;
        }

        #slide-left {
            right: 20px;
            left: auto;
        }
    }

    @media (max-width: 991.98px) {
        &.horizontalmenu {
            .main-body-1 {
                .nav {
                    padding: 0 15px 0 0;
                }

                li.nav-item.active .nav-link {
                    margin-left: auto;
                    margin-right: 0;
                    border-left: 0px;
                }

                .nav-item.active .nav-link {

                    &:before,
                    &:after {
                        border-right: 0;
                        border-left: 21px solid #0e0e23;
                    }

                    &:before,
                    &:after {
                        right: auto;
                        left: 0px;
                        border-left-color: #0e0e23;
                        border-right-color: transparent;
                    }

                    .shape1,
                    .shape2 {
                        right: auto;
                        left: 0px;
                    }
                }

                li.active .hor-icon {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }

            .hor-menu i.angle {
                right: auto;
                left: 20px;
            }

            .main-navbar .nav-item {
                padding-right: 0;
                padding-left: 0;
            }

            .main-body-1 .nav-link .hor-icon {
                margin-right: 0;
                margin-left: 10px;
            }

            .main-navbar .nav-link i {
                margin-right: auto;
                margin-left: 0;
            }

            .main-body-1 .nav-sub {
                margin-left: auto;
                margin-right: 1.2rem;

                .nav-sub-link:before {
                    margin-right: 0;
                    margin-left: 22px;
                }

                margin-left: 0;
                margin-right: 1.2rem;
            }

            .hor-menu {
                .nav-link:hover .sidemenu-label {
                    margin-left: 0;
                    margin-right: 5px;
                }

                .nav-item.show i.angle {
                    right: auto;
                    left: 20px;
                }
            }
        }
    }

    .side-menu {
        .nav-link:hover .sidemenu-label {
            margin-left: auto;
            margin-right: 5px;
            transition: margin-right 0.45s ease;
        }

        .sidemenu-label {
            transition: margin-right 0.45s ease;
        }

        i.angle,
        .nav-item.show i.angle {
            right: auto;
            left: 20px;
        }
    }

    .side-badge,
    &.main-sidebar-open.main-sidebar-hide .side-badge {
        right: auto;
        left: 18px;
    }

    @media (min-width: 992px) {
        &.main-sidebar-hide .main-sidebar-sticky+.main-content {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (max-width: 991.98px) {
        .main-sidebar {
            left: auto;
            right: 0;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar {
            border-right-width: 0;
            border-left-width: 1px;
        }
    }

    .main-sidebar-sticky {
        left: auto;
        right: 0;
    }

    .main-sidebar-body {
        .nav-item.active .nav-link {

            &:before,
            &:after {
                right: auto;
                left: 0px;
                border-left-color: #0e0e23;
                border-right-color: transparent;
            }

            .shape1,
            .shape2 {
                right: auto;
                left: 0px;
            }
        }

        li.nav-item.active .nav-link {
            margin-left: auto;
            margin-right: 0;
            border-right: 1px solid transparent;
            border-left: 0px;
            border-radius: 0 30px 30px 0;
        }

        .nav-item.active .nav-link {

            &:before,
            &:after {
                border-right: 0;
                border-left: 21px solid #0e0e23;
            }
        }
    }

    @media (min-width: 992px) {
        .main-sidebar-sticky+.main-content {
            margin-left: auto;
            margin-right: 220px;
        }
    }

    @media (min-width: 1200px) {
        .main-sidebar-sticky+.main-content {
            margin-left: auto;
            margin-right: 240px;
        }
    }

    .main-sidebar-loggedin .media-body {
        margin-left: auto;
        margin-right: 12px;
    }

    &.main-body .main-sidebar-body .nav {
        padding: 0 15px 0 0;
    }

    .nav-header {
        margin: 0 15px 0 0;
    }

    .main-sidebar-body {
        .nav-link .sidemenu-icon {
            margin-right: 0;
            margin-left: 10px;
        }

        .nav-sub {
            margin-left: auto;
            margin-right: 1.2rem;

            .nav-sub-link:before {
                margin-right: 0;
                margin-left: 22px;
            }
        }

        .nav-sub-item .nav-sub {
            padding: 0 12px 0 0;
            border-left-width: 0;
            border-right-width: 1px;
            margin-left: auto;
            margin-right: 0;
        }

        .nav-sub-link.with-sub::after {
            margin-left: 0;
            margin-right: auto;
        }

        li.active .sidemenu-icon {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    @media (min-width: 992px) {
        .main-sidebar-body .nav-sub-link.with-sub::after {
            margin-left: 0;
            margin-right: 4px;
        }
    }

    @media (min-width: 992px) {
        &.main-sidebar-hide.main-sidebar-open .main-sidebar-body {
            li.active {
                margin-left: auto;
                margin-right: 0;
                border-right: 1px solid transparent;
                border-left: 0px;
            }

            .nav-link .sidemenu-icon,
            li.active .sidemenu-icon {
                margin-right: 0;
                margin-left: 10px;
            }
        }

        &.main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
            padding: 0 15px 0 0;
        }

        &.main-sidebar-hide {
            .main-sidebar-body li.active .sidemenu-icon {
                margin-right: 5px !important;
                margin-left: 0;
            }

            &.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link {

                &:before,
                &:after {
                    right: auto;
                    left: 0px;
                    border-left-color: #0e0e23;
                    border-right-color: transparent;
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .side-content {
            margin-left: 0;
            margin-right: 240px;
        }
    }

    @media (min-width: 992px) {
        .side-header .main-logo {
            margin-right: auto;
            margin-left: 0;
        }

        &.main-sidebar-hide {

            .side-header,
            .main-footer {
                padding-left: inherit;
                padding-right: 70px;
            }
        }

        &.main-body {
            .side-header {
                left: auto;
                right: 0;
                padding-left: inherit;
                padding-right: 240px;
            }

            &.main-sidebar-hide .side-header {
                padding-left: inherit;
                padding-right: 75px;
            }
        }

        .main-content.side-content {
            margin-left: 0;
            margin-right: 240px;
        }
    }

    @media (max-width: 992px) and (min-width: 991px) {
        .main-header-menu-icon {
            margin-left: auto;
            margin-right: 15px;
        }
    }

    @media (max-width: 991px) {
        .main-header-menu-icon {
            margin-left: auto;
            margin-right: 15px;
        }

        .side-header .icon-logo {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (min-width: 992px) {
        &.main-sidebar-hide {
            .side-menu {
                left: auto;
                right: 0;
            }

            .main-content.side-content {
                margin-left: 0;
                margin-right: 80px;
            }

            &.main-sidebar-open .nav-header {
                margin: 0 15px 0 0;
            }

            .side-menu .main-logo .icon-logo {
                margin-left: auto;
                margin-right: 10px;
            }
        }

        &.main-sidebar-open {
            .side-menu {
                left: auto;
                right: 0;
            }

            .main-sidebar-body .side-menu .nav-link {
                margin-left: auto;
                margin-right: 10px;
            }

            .side-menu {
                .nav-item.show i.angle {
                    right: auto;
                    left: 20px;
                }

                .main-sidebar-body .nav-link i {
                    margin-right: auto;
                    margin-left: 5px;
                }
            }
        }
    }

    .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
        padding-right: 17px;
        padding-left: 20px;
    }

    @media (min-width: 992px) {
        .main-sidebar-header .sidemenu-logo {
            border-right: 0;
            border-left: 1px solid $dark-border;
        }
    }

    /* Closed Menu Start*/
    @media screen and (min-width: 992px) {
        &.closed-leftmenu {
            &.main-sidebar-hide {
                .side-menu {
                    left: auto !important;
                    right: -240px !important;
                }

                .main-content.side-content {
                    margin-right: 0px;
                    margin-left: auto;
                }
            }

            &.main-body.main-sidebar-hide .side-header {
                padding-left: inherit;
                padding-right: 0;
            }
        }
    }

    /* Closed Menu end*/
    /* Icon-Text Menu Start*/
    @media screen and (min-width: 992px) {
        &.icontext-menu.main-sidebar-hide {
            .main-content.side-content {
                margin-left: 0;
                margin-right: 100px;
            }

            .side-header {
                padding-left: inherit;
                padding-right: 100px;
            }

            &.main-sidebar-open .main-sidebar-body {
                .nav {
                    padding: 0 15px 0 0 !important;
                }

                .nav-link .sidemenu-icon {
                    margin-right: 5px !important;
                    margin-left: 10px !important;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        &.hover-submenu.main-sidebar-hide {
            .side-menu {
                left: auto;
                right: 0;
            }

            .side-header {
                padding-left: inherit;
                padding-right: 100px;
            }

            .main-content.side-content {
                margin-left: 0;
                margin-right: 100px;
            }

            .nav-sub {
                left: auto;
                right: 80px;
            }

            .side-menu {
                left: auto;
                right: 0;
            }

            .main-sidebar-body .nav-sub-item .sub-nav-sub {
                padding: 0 12px 0 0;
                border-left-width: 0;
                border-right-width: 1px;
                margin-left: auto;
                margin-right: 0;
            }

            .side-menu .nav-link:hover .sidemenu-label {
                margin-left: auto;
                margin-right: 0;
            }

            &.main-sidebar-open {
                .main-sidebar-body .nav-sub-item .sub-nav-sub {
                    padding: 0 12px 0 0;
                    border-left-width: 0;
                    border-right-width: 1px;
                    margin-left: auto;
                    margin-right: 0;
                }

                .nav-item:hover .nav-sub {
                    padding: 12px 15px 12px 0;
                }

                .side-menu {
                    left: auto;
                    right: 0;
                }

                .side-header {
                    padding-left: inherit;
                    padding-right: 100px;
                }

                .main-content.side-content {
                    margin-left: 0;
                    margin-right: 100px;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        &.hover-submenu1.main-sidebar-hide {
            .side-menu {
                left: auto;
                right: 0;
            }

            .side-header {
                padding-left: inherit;
                padding-right: 100px;
            }

            .main-content.side-content {
                margin-left: 0;
                margin-right: 100px;
            }

            .nav-sub {
                left: auto;
                right: 80px;
            }

            .side-menu {
                left: auto;
                right: 0;
            }

            .main-sidebar-body .nav-sub-item .sub-nav-sub {
                padding: 0 12px 0 0;
                border-left-width: 0;
                border-right-width: 1px;
                margin-left: auto;
                margin-right: 0;
            }

            .side-menu .nav-link:hover .sidemenu-label {
                margin-left: auto;
                margin-right: 0;
            }

            &.main-sidebar-open {
                .nav-item:hover .nav-sub-link {
                    padding: 8px 12px 8px 0;
                }

                .side-menu-label1 {
                    padding: 8px 12px 8px 0;
                    text-align: right;
                }

                .main-sidebar-body .nav-sub-item .sub-nav-sub {
                    padding: 0 12px 0 0;
                    border-left-width: 0;
                    border-right-width: 1px;
                    margin-left: auto;
                    margin-right: 0;
                }

                .side-menu {
                    left: auto;
                    right: 0;
                }

                .side-header {
                    padding-left: inherit;
                    padding-right: 100px;
                }

                .main-content.side-content {
                    margin-left: 0;
                    margin-right: 100px;
                }
            }
        }
    }

    .ui-datepicker {
        .ui-datepicker-header {
            .ui-datepicker-next:before {
                right: auto;
                left: 17px;
                transform: rotate(180deg);
            }

            .ui-datepicker-prev:before {
                left: auto;
                right: 17px;
                transform: rotate(180deg);
            }
        }

        .ui-datepicker-calendar td {
            text-align: left;

            &:last-child {
                border-right: 1px solid $border;
                border-left: 0;
            }
        }
    }

    .ui-datepicker-multi {
        .ui-datepicker-group {
            padding-right: inherit;
            padding-left: 15px;
            float: right;

            .ui-datepicker-prev::before {
                left: auto;
                right: 10px;
            }

            .ui-datepicker-next::before {
                right: auto;
                left: 10px;
            }
        }

        .ui-datepicker-group-last {
            padding-right: inherit;
            padding-left: 0;
        }
    }

    .datetimepicker table th {
        &.prev span::before {
            left: auto;
            right: 0;
        }

        &.next span::before {
            right: auto;
            left: 0;
        }
    }

    .main-datetimepicker> {
        .datepicker_header a {
            &:nth-child(2)::before {
                left: auto;
                right: 0;
            }

            &:last-child::before {
                right: auto;
                left: 0;
            }
        }

        .datepicker_inner_container>.datepicker_timelist {
            border-left: 0;
            border-right: 1px solid $border;

            >div.timelist_item {
                margin: 0 1px 0 0;
            }
        }
    }

    #jqvmap1_ca_pin {
        margin-left: auto;
        margin-right: -2%;
    }

    #jqvmap1_ct_pin {
        margin-left: auto;
        margin-right: -0.25%;
    }

    #jqvmap1_fl_pin {
        margin-left: auto;
        margin-right: 5%;
    }

    #jqvmap1_id_pin {
        margin-left: auto;
        margin-right: -1%;
    }

    #jqvmap1_ky_pin {
        margin-left: auto;
        margin-right: 2%;
    }

    #jqvmap1_la_pin {
        margin-left: auto;
        margin-right: -2%;
    }

    #jqvmap1_mi_pin {
        margin-left: auto;
        margin-right: 3%;
    }

    #jqvmap1_mn_pin {
        margin-left: auto;
        margin-right: -2%;
    }

    #jqvmap1_nh_pin {
        margin-left: auto;
        margin-right: -0.25%;
    }

    #jqvmap1_ok_pin,
    #jqvmap1_va_pin {
        margin-left: auto;
        margin-right: 2%;
    }

    #jqvmap1_wv_pin {
        margin-left: auto;
        margin-right: -1%;
    }

    .main-donut-chart .slice,
    .chart-donut {
        left: auto;
        right: 0;
    }

    .flot-chart-wrapper {
        margin-right: -16px;
        margin-left: -30px;
    }

    .chart-legEnd {
        div+div {
            margin-left: auto;
            margin-right: 15px;
        }

        span {
            margin-right: auto;
            margin-left: 5px;
        }
    }

    @media (min-width: 576px) {
        .chart-legEnd {
            right: auto;
            left: 40px;

            span {
                margin-right: auto;
                margin-left: 8px;
            }

            div+div {
                margin-left: auto;
                margin-right: 30px;
            }
        }
    }

    .mapael .zoomButton {
        left: auto;
        right: 10px;
    }

    @media (min-width: 768px) {
        .ql-scrolling-demo {
            padding-left: inherit;
            padding-right: 15px;
        }
    }

    .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0 0 8px;

        &:first-child {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (min-width: 576px) {
        .ql-bubble .ql-toolbar .ql-formats:first-child {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .ql-snow {
        &.ql-toolbar .ql-formats {
            margin-right: auto;
            margin-left: 5px;
        }

        .ql-formats button+button {
            margin: 0 2px 0 0;
        }

        .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
            right: auto;
            left: 3px;
        }

        .ql-picker-label {
            padding-left: inherit;
            padding-right: 10px;
        }
    }

    .irs-bar-edge,
    .irs-line-left {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .irs-line-right {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .irs-slider:before {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -1px;
    }

    .irs-modern .irs-slider::before {
        margin-left: auto;
        margin-right: -2px;
    }

    .irs-outline {
        .irs-bar-edge {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .irs-slider::before {
            margin-left: auto;
            margin-right: -4px;
        }
    }

    .select2-container--default {
        .select2-selection--single .select2-selection__rendered {
            padding-left: 20px;
            padding-right: 0.75rem;
        }

        .select2-selection--multiple {
            .select2-selection__choice {
                margin-right: auto;
                margin-left: 4px;
                padding: 3px 20px 3px 10px !important;
            }

            .select2-selection__choice__remove {
                left: auto;
                right: 7px;
            }
        }

        .select2-search--inline .select2-search__field {
            padding-left: inherit;
            padding-right: 7px;
        }

        &.select2-container--disabled .select2-selection__choice {
            padding-left: inherit;
            padding-right: 10px;
        }
    }

    .sp-picker-container {
        border-left: 1px solid $border;
        border-right: 0;
    }

    .sp-dd::before {
        left: auto;
        right: 0;
    }

    .lds-facebook div {
        left: auto;
        right: 6px;

        &:nth-child(1) {
            left: auto;
            right: 6px;
        }

        &:nth-child(2) {
            left: auto;
            right: 26px;
        }

        &:nth-child(3) {
            left: auto;
            right: 45px;
        }
    }


    .lds-spinner div:after {
        right: 29px;
        left: auto;
    }

    .pulse {
        right: auto;
        left: 17px;
    }

    .ripple:after {
        left: auto;
        right: 0;
    }

    .main-nav-calendar-event .nav-link i {
        margin-right: auto;
        margin-left: 10px;
    }

    @media (min-width: 992px) {
        .main-content-body-calendar {
            padding-left: inherit;
            padding-right: 25px;
        }
    }

    .main-calendar {
        .fc-view {
            .fc-day-number {
                margin: 2px 0 0 2px;
            }

            &.fc-listMonth-view .fc-list-item,
            &.fc-listWeek-view .fc-list-item {
                border-left: 0;
                border-right: 4px solid transparent;
            }

            &.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
            &.fc-listWeek-view .fc-list-item>td.fc-list-item-time,
            &.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
            &.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
                border-right: 0;
                border-left: 1px solid $border;
            }

            &.fc-listMonth-view .fc-list-item+.fc-list-item,
            &.fc-listWeek-view .fc-list-item+.fc-list-item {
                margin-left: auto;
                margin-right: 20%;
            }
        }

        .fc-time-grid-event {
            border-left-width: 1px;
            border-right-width: 0;
        }
    }

    @media (max-width: 575px) {
        .main-calendar .fc-header-toolbar button {

            &.fc-month-button::before,
            &.fc-agendaWeek-button::before,
            &.fc-agendaDay-button::before,
            &.fc-listWeek-button::before,
            &.fc-listMonth-button::before {
                left: auto;
                right: 10px;
            }

            &.fc-listWeek-button::before,
            &.fc-listMonth-button::before {
                left: auto;
                right: 11px;
            }
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view>table>thead {

            th:first-child,
            td:first-child {
                border-left-width: 1px;
                border-right-width: 0;
            }

            th:last-child,
            td:last-child {
                border-right-width: 1px;
                border-left-width: 0;
            }
        }
    }

    @media (max-width: 991.98px) {
        .main-calendar .fc-view>table>tbody>tr>td {
            &:first-child {
                border-left-width: 1px;
                border-right-width: 0;
            }

            &:last-child {
                border-right-width: 1px;
                border-left-width: 0;
            }
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-view {

            &.fc-listMonth-view .fc-list-item+.fc-list-item,
            &.fc-listWeek-view .fc-list-item+.fc-list-item {
                margin-left: auto;
                margin-right: 15%;
            }
        }
    }

    @media (min-width: 768px) {
        .main-calendar .fc-view {

            &.fc-listMonth-view .fc-list-item+.fc-list-item,
            &.fc-listWeek-view .fc-list-item+.fc-list-item {
                margin-left: auto;
                margin-right: 12%;
            }
        }
    }

    @media (min-width: 992px) {
        .main-calendar .fc-view {

            &.fc-listMonth-view .fc-list-item+.fc-list-item,
            &.fc-listWeek-view .fc-list-item+.fc-list-item {
                margin-left: auto;
                margin-right: 10%;
            }
        }
    }

    @media (min-width: 1200px) {
        .main-calendar .fc-view {

            &.fc-listMonth-view .fc-list-item+.fc-list-item,
            &.fc-listWeek-view .fc-list-item+.fc-list-item {
                margin-left: auto;
                margin-right: 8%;
            }
        }
    }

    @media (min-width: 576px) {
        .main-calendar .fc-event {
            border-left-width: 0;
            border-right-width: 2px;
        }
    }

    .main-modal-calendar-schedule {
        .modal-content>.close {
            right: auto;
            left: 25px;
        }

        .form-group-date>i {
            margin-right: auto;
            margin-left: 10px;
        }
    }

    .main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
        margin-left: auto;
        margin-right: 15px;
    }

    .main-nav-line-chat {
        padding-left: inherit;
        padding-right: 20px;

        .nav-link+.nav-link {
            margin-left: 0;
            margin-right: 30px;
        }
    }

    @media (min-width: 992px) {
        .main-chat-contacts-wrapper {
            padding: 10px 0 10px 15px;
        }
    }

    .main-chat-contacts {
        padding-left: inherit;
        padding-right: 20px;
    }

    .main-chat-list {
        .main-img-user span {
            left: auto;
            right: -2px;
        }

        .media-body {
            margin-left: auto;
            margin-right: 15px;
        }
    }

    .main-chat-header {
        .nav {
            margin-left: 0;
            margin-right: auto;
        }

        .nav-link+.nav-link {
            margin-left: auto;
            margin-right: 15px;
        }
    }

    .main-chat-msg-name {
        margin-right: 15px;
        margin-left: auto;
    }

    .main-chat-body .media.flex-row-reverse {
        .media-body {
            margin-left: 20px;
            margin-right: 0;
        }

        .main-msg-wrapper {

            &:before,
            &:after {
                left: auto;
                right: 100%;
                border-right-color: #e9e9f9 !important;
                border-left-color: transparent !important;
            }
        }
    }

    @media (min-width: 576px) {
        .main-chat-body .media.flex-row-reverse .media-body {
            margin-left: 20px;
            margin-right: 55px;
        }
    }

    .main-msg-wrapper {

        &:before,
        &:after {
            right: auto;
            left: 100%;
        }
    }

    .main-chat-body .media-body {
        margin-left: auto;
        margin-right: 20px;
    }

    @media (min-width: 576px) {
        .main-chat-body .media-body {
            margin-right: 20px;
            margin-left: 55px;
        }
    }

    .main-chat-footer .nav-link+.nav-link {
        margin-left: auto;
        margin-right: 10px;
    }

    @media (min-width: 576px) {
        .main-chat-footer .form-control {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    .main-content-left-contacts {

        .main-content-breadcrumb,
        .main-content-title {
            padding-right: 20px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-contacts {

            .main-content-breadcrumb,
            .main-content-title {
                padding-left: inherit;
                padding-right: 0;
            }
        }
    }

    .main-contact-label {
        padding-right: 20px;
        padding-left: inherit;
    }

    .main-contact-item {
        border-right-width: 1px;
        border-left-width: 0;

        &.selected {
            border-left-color: transparent;
            border-right-color: #eaedf7;
        }
    }

    .main-contact-body {
        margin-left: auto;
        margin-right: 15px;
    }

    .main-contact-info-header {
        padding-left: inherit;
        padding-right: 20px;

        .main-img-user a {
            right: auto;
            left: 0;
        }

        .media-body .nav-link {
            i {
                margin-right: auto;
                margin-left: 5px;
            }

            +.nav-link {
                margin-left: auto;
                margin-right: 10px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-contact-info-header .media-body {
            margin-left: auto;
            margin-right: 30px;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link i {
            margin-right: auto;
            margin-left: 7px;
        }
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link+.nav-link {
            margin-left: auto;
            margin-right: 25px;
        }
    }

    .main-contact-action {
        right: auto;
        left: 20px;
    }

    @media (min-width: 992px) {
        .main-contact-action {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-column-signup {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    .main-signup-header .row>div {

        .btn i,
        .sp-container button i {
            margin-right: auto;
            margin-left: 5px;
        }
    }

    .sp-container .main-signup-header .row>div button i {
        margin-right: auto;
        margin-left: 5px;
    }

    .signpages .details {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .construction {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        &:before {
            left: auto;
            right: 0;
        }
    }

    .signpages {
        .details:before {
            left: auto;
            right: 0;
        }

        .login_form {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        .form__input {
            padding-left: inherit;
            padding-right: 2em;
        }
    }

    @media screen and (max-width: 640px) {
        .signpages .login_form {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    &.error-1 .demo-icon {
        right: auto !important;
        left: 60px !important;
    }

    @media (max-width: 991px) {
        &.error-1 .demo-icon {
            right: auto !important;
            left: 30px !important;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-invoice {
            border-right: 0;
            border-left: 1px solid $border;
        }
    }

    .main-invoice-list {
        .media {
            border-right-width: 1px;
            border-left-width: 0;

            +.media::before {
                right: 55px;
                left: 0;
            }
        }

        .media-body {
            margin-left: auto;
            margin-right: 15px;
        }
    }

    .table-invoice tbody>tr> {

        th:first-child .invoice-notes,
        td:first-child .invoice-notes {
            margin-right: auto;
            margin-left: 20px;
        }
    }

    .main-content-left-invoice {

        .main-content-breadcrumb,
        .main-content-title {
            padding-left: inherit;
            padding-right: 20px;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-invoice {

            .main-content-breadcrumb,
            .main-content-title {
                padding-left: inherit;
                padding-right: 0;
            }
        }
    }

    @media (min-width: 992px) {
        .main-content-left-mail .btn-compose {
            margin-right: auto;
            margin-left: 0;
        }
    }

    .main-task-menu {
        padding-right: inherit;
        padding-left: 0;
    }

    @media (min-width: 992px) {
        .main-task-menu {
            padding-right: inherit;
            padding-left: 0;
        }
    }

    .main-mail-header {
        >div:last-child>span {
            margin-right: auto;
            margin-left: 10px;
        }

        .btn-group {

            .btn+.btn,
            .sp-container button+.btn {
                margin-left: auto;
                margin-right: -2px;
            }
        }
    }

    .sp-container .main-mail-header .btn-group button+.btn,
    .main-mail-header .btn-group .sp-container .btn+button,
    .sp-container .main-mail-header .btn-group .btn+button,
    .main-mail-header .btn-group .sp-container button+button,
    .sp-container .main-mail-header .btn-group button+button {
        margin-left: auto;
        margin-right: -2px;
    }

    .main-mail-options {
        padding: 5px 20px 5px 10px;
    }

    .main-mail-item {

        .main-img-user,
        .main-avatar {
            margin-right: auto;
            margin-left: 15px;
        }
    }

    .main-mail-checkbox {
        margin-right: auto;
        margin-left: 15px;
    }

    .main-mail-star {
        margin-right: auto;
        margin-left: 15px;
        right: auto;
        left: 0;
    }

    @media (min-width: 992px) {
        .main-mail-body {
            margin-right: auto;
            margin-left: 15px;
        }
    }

    @media (min-width: 992px) {
        .task-actions {
            float: left;
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (max-width: 991px) {
        .mail-container .tab-list-items {

            &.task-menu-show,
            &.mail-menu-show {
                left: auto;
                right: 0;
                padding-left: inherit;
                padding-right: 27px;
            }
        }

        .task-actions {
            float: left;
            margin-left: auto;
            margin-right: 0;
        }
    }

    .main-mail-attachment {
        margin-right: auto;
        margin-left: 15px;
    }

    @media (min-width: 992px) {
        .main-mail-attachment {
            right: auto;
            left: 63px;
        }
    }

    .card-box img {
        left: auto;
        right: -17px;
        transform: scaleX(-1);
    }

    .main-mail-date {
        right: auto;
        left: 15px;
        margin-left: 0;
        margin-right: auto;
    }

    .main-mail-compose {
        left: auto;
        right: 0;
    }

    .main-mail-compose-header .nav-link+.nav-link {
        margin-left: auto;
        margin-right: 15px;
    }

    .main-mail-compose-body .form-group {
        >div {
            margin-left: auto;
            margin-right: 10px;
        }

        .nav-link+.nav-link {
            margin-left: auto;
            margin-right: 15px;
        }
    }

    .main-mail-compose-compress,
    .main-mail-compose-minimize {
        right: auto;
        left: 30px;
    }

    .main-mail-two .main-header-menu-icon {
        margin-right: auto;
        margin-left: 0;
    }

    .main-mail-left {
        border-right: 0;
        border-left: 1px solid $border;

        .main-task-menu,
        .main-mail-menu {
            padding-right: inherit;
            padding-left: 0;
        }
    }

    ul.inbox-pagination {
        float: left;
    }

    .inbox-pagination {
        li span {
            margin-right: auto;
            margin-left: 5px;
        }

        a.np-btn {
            margin-left: auto;
            margin-right: 5px;
        }
    }

    .profile-cover__action {
        padding: 216px 185px 10px 30px;

        >.btn {
            margin-left: auto;
            margin-right: 10px;
        }
    }

    .profile-cover__img {
        left: auto;
        right: 50px;
    }

    @media (min-width: 601px) {
        .profile-cover__info .nav {
            padding: 15px 170px 10px 0;
        }
    }

    .profile-cover__info .nav li:not(:first-child) {
        margin-right: 30px;
        margin-left: 0;
        padding-left: inherit;
        padding-right: 30px;
        border-left: 0;
        border-right: 1px solid rgba(238, 238, 238, 0.2);
    }

    @media (max-width: 600px) {
        .profile-cover__info .nav {
            margin-right: auto;
            margin-left: 28px;
            padding: 39px 170px 10px 0;
            margin-right: auto;
            margin-left: 0;

            li {
                margin-right: auto;
                margin-left: 1rem;

                &:not(:first-child) {
                    margin-left: auto;
                    margin-right: 0;
                    padding-left: inherit;
                    padding-right: 0;
                    border-left-width: 1px;
                    border-right-width: 0;
                }
            }
        }

        .main-nav-line .nav-link+.nav-link {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-profile {
            padding-right: inherit;
            padding-left: 20px;
            border-right: 0;
            border-left: 1px solid $border;
        }
    }

    @media (min-width: 1200px) {
        .main-content-left-profile {
            padding-right: inherit;
            padding-left: 25px;
        }
    }

    .main-profile-social-list .media-body {
        margin-left: auto;
        margin-right: 20px;
    }

    @media (min-width: 992px) {
        .main-content-body-profile .nav {
            padding-left: inherit;
            padding-right: 20px;
        }
    }

    @media (min-width: 1200px) {
        .main-content-body-profile .nav {
            padding-left: inherit;
            padding-right: 25px;
        }
    }

    @media (min-width: 992px) {
        .main-profile-body {
            padding: 25px 20px 0 0;
        }
    }

    @media (min-width: 1200px) {
        .main-profile-body {
            padding-left: inherit;
            padding-right: 25px;
        }
    }

    .main-profile-view-info {
        left: auto;
        right: 0;

        span {
            margin-left: auto;
            margin-right: 5px;
        }
    }

    .main-profile-work-list .media-body {
        margin-left: auto;
        margin-right: 20px;
    }

    .main-profile-contact-list .media-body {
        margin-left: auto;
        margin-right: 25px;
    }

    .btn-profile {
        right: auto;
        left: 50px;
    }

    @media (max-width: 767px) {
        .profile-cover__info .nav {
            margin-right: auto;
            margin-left: 0;

            li {
                margin-right: 0;
                margin-left: 1rem !important;

                &:not(:first-child) {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: inherit;
                    padding-right: 0;
                    border-left-width: 1px;
                    border-right-width: 0;
                }
            }
        }

        .main-nav-line .nav-link+.nav-link {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .card-bitcoin .media-body {
        margin-left: auto;
        margin-right: 20px;
    }

    .crypto-card .chart-wrapper {
        left: 0;
        right: -68px;
    }

    .exchange-icon {
        left: auto;
        right: -51px;
    }

    .payment-list {
        padding-left: inherit;
        padding-right: 0;
    }

    .cryptoicon {
        margin-right: auto;
        margin-left: 15px;
        float: right;
        margin-top: 11px;
    }

    .select2-results__option {

        .cryptoicon {
            margin-top: 0;
        }
    }

    .form input:checked+label {
        &:after {
            right: auto;
            left: 0;
            border-width: 0 0 35px 35px;
        }

        &:before {
            right: auto;
            left: 4px;
        }
    }

    .crypto-buysell-card .nav.panel-tabs li .active:before {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -10px;
    }

    .crypto-wallet .clipboard-icon {
        right: auto;
        left: 0;

        &:hover,
        &:focus {
            right: auto;
            left: 0;
        }
    }

    .wallet {
        .clipboard-icon {
            right: auto;
            left: 0;
            border-left: 1px solid $border;
            border-right: 0;
        }

        .input-group-addon-left {
            border-right: 1px solid $border;
            border-left: 0;
        }
    }

    .transcation-crypto {
        .table.dataTable {

            th,
            td {
                border-left: 0;
                border-right: 0;
            }
        }

        table.dataTable thead {

            .sorting:before,
            .sorting_asc:before,
            .sorting_desc:before,
            .sorting_asc_disabled:before,
            .sorting_desc_disabled:before {
                right: auto;
                left: 1em;
            }

            .sorting:after,
            .sorting_asc:after,
            .sorting_desc:after {
                right: auto;
                left: 0.5em;
            }
        }
    }

    table.dataTable thead .sorting_asc_disabled:after,
    .transcation-crypto table.dataTable thead .sorting_desc_disabled:after {
        right: auto;
        left: 0.5em;
    }

    .ratio {
        right: auto;
        left: -10px;
    }

    .product-timeline ul.timeline-1 {
        padding-right: 2rem;
        padding-left: initial;
        >li {
            padding-left: inherit;
            padding-right: 1rem;

            .product-icon {
                left: auto;
                right: 3px;
            }
        }

        &::before {
            margin: 20px 22px 0 20px;
            left: auto;
            right: -2px;
            border-left: 0;
            border-right: 2px dotted rgba(98, 89, 202, 0.3);
        }
    }

    .countrywise-sale .list-group-item {
        span {
            margin-left: 0;
            margin-right: auto;
        }

        p {
            margin-left: auto;
            margin-right: 10px;
        }
    }

    .top-selling-products {
        .product-img {
            margin-right: 0;
            margin-left: 1rem;
        }

        .product-price {
            margin-left: 0;
            margin-right: auto;
            text-align: left;
        }

        .product-amount {
            float: left;
            margin-left: 0;
            margin-right: auto;
        }
    }

    .table-filter {
        select {
            margin-left: auto;
            margin-right: 5px;
        }

        .btn {
            margin-left: auto;
            margin-right: -1px;
            float: left;
            border-radius: 4px 0 0 4px;
        }

        input {
            margin-left: auto;
            margin-right: 5px;
        }
    }

    .status {
        margin: 2px 0 4px 6px;
    }

    #checkoutsteps {
        .item .purchase {
            margin-left: auto;
            margin-right: 21px;
        }

        .checkout span.heading {
            margin-right: auto;
            margin-left: 5px;
        }

        .total {
            float: left;
        }
    }

    .card-pay .tabs-menu.nav li {
        border-right: 0;
        border-left: 1px solid #d3dfea;

        a {
            border-right: 0;
            border-left: 0;
        }
    }

    .item1-links a i {
        margin-right: auto;
        margin-left: 1rem;
    }

    .track .step::before,
    .product-image .pic-2 {
        left: auto;
        right: 0;
    }

    .product-grid {

        &:hover .product-image .pic-2,
        .product-discount-label {
            left: auto;
            right: 10px;
        }

        .product-like-icon {
            right: auto;
            left: -50px;
        }

        &:hover .product-like-icon {
            right: auto;
            left: 10px;
        }

        .product-link {
            left: auto;
            right: 0;

            a {
                border-right: 0;
                border-left: 2px solid rgba(215, 215, 215, 0.15);

                &:nth-child(2n) {
                    border-right: 0;
                    border-left: none;
                }
            }
        }
    }

    .product-content .rating {
        padding-left: inherit;
        padding-right: 0;
    }

    .product-grid .price .old-price {
        margin-right: auto;
        margin-left: 12px;
    }

    #wishlist .product-grid {
        text-align: right;

        .product-content {
            text-align: right;
        }

        .wishlist-icon {
            right: auto;
            left: 10px;
        }
    }

    .notify-time {
        right: auto;
        left: 25px;
    }

    .notification {
        &:before {
            left: auto;
            right: 20%;
            margin-left: auto;
            margin-right: -1px;
        }

        .notification-time {
            left: auto;
            right: 0;
            text-align: left;
        }

        .notification-icon {
            left: auto;
            right: 15%;
        }

        .notification-body {
            margin-right: 24%;
            margin-left: 18%;

            &:before {
                border-right-color: transparent;
                border-left-color: $white;
                left: auto;
                right: -20px;
            }
        }
    }

    .border-end {
        border-right: 0 !important;
        border-left: 1px solid $border;
    }

    .border-start {
        border-left: 0 !important;
        border-right: 1px solid $border;
    }

    .border-end-0 {
        border-right: 1px solid $border !important;
        border-left: 0;
    }

    .border-start-0 {
        border-right: 0;
        border-left: 1px solid $border!important;
    }

    .rounded-end {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .rounded-start {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }

    .rounded-end-0 {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }

    .rounded-start-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .rounded-end-11 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 11px !important;
        border-bottom-left-radius: 11px !important;
    }

    .rounded-start-11 {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
    }

    .bd-r {
        border-right: 0;
        border-left: 1px solid $border;
    }

    .bd-l {
        border-left: 0;
        border-right: 1px solid $border;
    }

    .bd-r-0 {
        border-right: 1px solid $border;
        border-left: 0;
    }

    .bd-l-0 {
        border-left: 1px solid $border;
        border-right: 0;
    }

    @media (min-width: 480px) {
        .bd-xs-r {
            border-right: 0;
            border-left: 1px solid $border;
        }

        .bd-xs-l {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    @media (min-width: 576px) {
        .bd-sm-r {
            border-right: 0;
            border-left: 1px solid $border;
        }

        .bd-sm-l {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    @media (min-width: 768px) {
        .bd-md-r {
            border-right: 0;
            border-left: 1px solid $border;
        }

        .bd-md-l {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    @media (min-width: 992px) {
        .bd-lg-r {
            border-right: 0;
            border-left: 1px solid $border;
        }

        .bd-lg-l {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    @media (min-width: 1200px) {
        .bd-xl-r {
            border-right: 0;
            border-left: 1px solid $border;
        }

        .bd-xl-l {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    .mg-r-0 {
        margin-right: auto;
        margin-left: 0px;
    }

    .mg-l-0 {
        margin-left: auto;
        margin-right: 0px;
    }

    .mg-r-1 {
        margin-right: auto;
        margin-left: 1px;
    }

    .mg-l-1 {
        margin-left: auto;
        margin-right: 1px;
    }

    .mg-r-2 {
        margin-right: auto;
        margin-left: 2px;
    }

    .mg-l-2 {
        margin-left: auto;
        margin-right: 2px;
    }

    .mg-r-3 {
        margin-right: auto;
        margin-left: 3px;
    }

    .mg-l-3 {
        margin-left: auto;
        margin-right: 3px;
    }

    .mg-r-4 {
        margin-right: auto;
        margin-left: 4px;
    }

    .mg-l-4 {
        margin-left: auto;
        margin-right: 4px;
    }

    .mg-r-5 {
        margin-right: auto;
        margin-left: 5px;
    }

    .mg-l-5 {
        margin-left: auto;
        margin-right: 5px;
    }

    .mg-r-6 {
        margin-right: auto;
        margin-left: 6px;
    }

    .mg-l-6 {
        margin-left: auto;
        margin-right: 6px;
    }

    .mg-r-7 {
        margin-right: auto;
        margin-left: 7px;
    }

    .mg-l-7 {
        margin-left: auto;
        margin-right: 7px;
    }

    .mg-r-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .mg-l-8 {
        margin-left: auto;
        margin-right: 8px;
    }

    .mg-r-9 {
        margin-right: auto;
        margin-left: 9px;
    }

    .mg-l-9 {
        margin-left: auto;
        margin-right: 9px;
    }

    .mg-r-10 {
        margin-right: auto;
        margin-left: 10px;
    }

    .mg-l-10 {
        margin-left: auto;
        margin-right: 10px;
    }

    .mg-r-15 {
        margin-right: 0;
        margin-left: 15px;
    }

    .mg-l-15 {
        margin-left: auto;
        margin-right: 15px;
    }

    .mg-r-20 {
        margin-right: auto;
        margin-left: 20px;
    }

    .mg-l-20 {
        margin-left: 0;
        margin-right: 20px;
    }

    .mg-r-25 {
        margin-right: auto;
        margin-left: 25px;
    }

    .mg-l-25 {
        margin-left: auto;
        margin-right: 25px;
    }

    .mg-r-30 {
        margin-right: auto;
        margin-left: 30px;
    }

    .mg-l-30 {
        margin-left: auto;
        margin-right: 30px;
    }

    .mg-r-35 {
        margin-right: auto;
        margin-left: 35px;
    }

    .mg-l-35 {
        margin-left: auto;
        margin-right: 35px;
    }

    .mg-r-40 {
        margin-right: auto;
        margin-left: 40px;
    }

    .mg-l-40 {
        margin-left: auto;
        margin-right: 40px;
    }

    .mg-r-45 {
        margin-right: auto;
        margin-left: 45px;
    }

    .mg-l-45 {
        margin-left: auto;
        margin-right: 45px;
    }

    .mg-r-50 {
        margin-right: auto;
        margin-left: 50px;
    }

    .mg-l-50 {
        margin-left: auto;
        margin-right: 50px;
    }

    .mg-r-55 {
        margin-right: auto;
        margin-left: 55px;
    }

    .mg-l-55 {
        margin-right: 55px;
        margin-left: auto;
    }

    .mg-r-60 {
        margin-right: auto;
        margin-left: 60px;
    }

    .mg-l-60 {
        margin-left: auto;
        margin-right: 60px;
    }

    .mg-r-65 {
        margin-right: auto;
        margin-left: 65px;
    }

    .mg-l-65 {
        margin-left: auto;
        margin-right: 65px;
    }

    .mg-r-70 {
        margin-right: auto;
        margin-left: 70px;
    }

    .mg-l-70 {
        margin-left: auto;
        margin-right: 70px;
    }

    .mg-r-75 {
        margin-right: auto;
        margin-left: 75px;
    }

    .mg-l-75 {
        margin-left: auto;
        margin-right: 75px;
    }

    .mg-r-80 {
        margin-right: auto;
        margin-left: 80px;
    }

    .mg-l-80 {
        margin-left: auto;
        margin-right: 80px;
    }

    .mg-r-85 {
        margin-right: auto;
        margin-left: 85px;
    }

    .mg-l-85 {
        margin-left: auto;
        margin-right: 85px;
    }

    .mg-r-90 {
        margin-right: auto;
        margin-left: 90px;
    }

    .mg-l-90 {
        margin-left: auto;
        margin-right: 90px;
    }

    .mg-r-95 {
        margin-right: auto;
        margin-left: 95px;
    }

    .mg-l-95 {
        margin-left: auto;
        margin-right: 95px;
    }

    .mg-r-100 {
        margin-right: auto;
        margin-left: 100px;
    }

    .mg-l-100 {
        margin-left: auto;
        margin-right: 100px;
    }

    .mg-r-105 {
        margin-right: auto;
        margin-left: 105px;
    }

    .mg-l-105 {
        margin-left: auto;
        margin-right: 105px;
    }

    .mg-r-110 {
        margin-right: auto;
        margin-left: 110px;
    }

    .mg-l-110 {
        margin-left: 110px;
    }

    .mg-r-115 {
        margin-left: 115px;
        margin-right: auto;
    }

    .mg-l-115 {
        margin-left: auto;
        margin-right: 115px;
    }

    .mg-r-120 {
        margin-right: auto;
        margin-left: 120px;
    }

    .mg-l-120 {
        margin-left: auto;
        margin-right: 120px;
    }

    .mg-r-auto {
        margin-right: 0;
        margin-left: auto;
    }

    .mg-l-auto {
        margin-left: 0;
        margin-right: auto;
    }

    @media (min-width: 480px) {
        .mg-xs-r-0 {
            margin-right: auto;
            margin-left: 0px;
        }

        .mg-xs-l-0 {
            margin-left: auto;
            margin-right: 0px;
        }

        .mg-xs-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-xs-l-1 {
            margin-left: auto;
            margin-right: 1px;
        }

        .mg-xs-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-xs-l-2 {
            margin-left: auto;
            margin-right: 2px;
        }

        .mg-xs-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-xs-l-3 {
            margin-left: auto;
            margin-right: 3px;
        }

        .mg-xs-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-xs-l-4 {
            margin-left: auto;
            margin-right: 4px;
        }

        .mg-xs-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-xs-l-5 {
            margin-left: auto;
            margin-right: 5px;
        }

        .mg-xs-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-xs-l-6 {
            margin-left: auto;
            margin-right: 6px;
        }

        .mg-xs-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-xs-l-7 {
            margin-left: auto;
            margin-right: 7px;
        }

        .mg-xs-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-xs-l-8 {
            margin-left: auto;
            margin-right: 8px;
        }

        .mg-xs-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-xs-l-9 {
            margin-left: auto;
            margin-right: 9px;
        }

        .mg-xs-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-xs-l-10 {
            margin-left: auto;
            margin-right: 10px;
        }

        .mg-xs-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-xs-l-15 {
            margin-left: auto;
            margin-right: 15px;
        }

        .mg-xs-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-xs-l-20 {
            margin-left: auto;
            margin-right: 20px;
        }

        .mg-xs-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-xs-l-25 {
            margin-left: auto;
            margin-right: 25px;
        }

        .mg-xs-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-xs-l-30 {
            margin-left: auto;
            margin-right: 30px;
        }

        .mg-xs-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-xs-l-35 {
            margin-left: auto;
            margin-right: 35px;
        }

        .mg-xs-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-xs-l-40 {
            margin-left: auto;
            margin-right: 40px;
        }

        .mg-xs-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-xs-l-45 {
            margin-left: auto;
            margin-right: 45px;
        }

        .mg-xs-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-xs-l-50 {
            margin-left: auto;
            margin-right: 50px;
        }

        .mg-xs-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-xs-l-55 {
            margin-left: auto;
            margin-right: 55px;
        }

        .mg-xs-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-xs-l-60 {
            margin-left: auto;
            margin-right: 60px;
        }

        .mg-xs-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-xs-l-65 {
            margin-left: auto;
            margin-right: 65px;
        }

        .mg-xs-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-xs-l-70 {
            margin-left: auto;
            margin-right: 70px;
        }

        .mg-xs-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-xs-l-75 {
            margin-left: auto;
            margin-right: 75px;
        }

        .mg-xs-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-xs-l-80 {
            margin-left: auto;
            margin-right: 80px;
        }

        .mg-xs-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-xs-l-85 {
            margin-left: auto;
            margin-right: 85px;
        }

        .mg-xs-r-90 {
            margin-right: auto;
            margin-left: 90px;
        }

        .mg-xs-l-90 {
            margin-left: auto;
            margin-right: 90px;
        }

        .mg-xs-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-xs-l-95 {
            margin-left: auto;
            margin-right: 95px;
        }

        .mg-xs-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-xs-l-100 {
            margin-left: auto;
            margin-right: 100px;
        }

        .mg-xs-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-xs-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 576px) {
        .mg-sm-r-0 {
            margin-right: auto;
            margin-left: 0px;
        }

        .mg-sm-l-0 {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-sm-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-sm-l-1 {
            margin-left: auto;
            margin-right: 1px;
        }

        .mg-sm-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-sm-l-2 {
            margin-left: auto;
            margin-right: 2px;
        }

        .mg-sm-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-sm-l-3 {
            margin-left: auto;
            margin-right: 3px;
        }

        .mg-sm-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-sm-l-4 {
            margin-left: auto;
            margin-right: 4px;
        }

        .mg-sm-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-sm-l-5 {
            margin-left: auto;
            margin-right: 5px;
        }

        .mg-sm-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-sm-l-6 {
            margin-left: auto;
            margin-right: 6px;
        }

        .mg-sm-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-sm-l-7 {
            margin-left: auto;
            margin-right: 7px;
        }

        .mg-sm-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-sm-l-8 {
            margin-left: auto;
            margin-right: 8px;
        }

        .mg-sm-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-sm-l-9 {
            margin-left: auto;
            margin-right: 9px;
        }

        .mg-sm-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-sm-l-10 {
            margin-left: auto;
            margin-right: 10px;
        }

        .mg-sm-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-sm-l-15 {
            margin-left: auto;
            margin-right: 15px;
        }

        .mg-sm-r-20 {
            margin-right: 0;
            margin-left: 20px;
        }

        .mg-sm-l-20 {
            margin-left: auto;
            margin-right: 20px;
        }

        .mg-sm-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-sm-l-25 {
            margin-left: auto;
            margin-right: 25px;
        }

        .mg-sm-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-sm-l-30 {
            margin-left: auto;
            margin-right: 30px;
        }

        .mg-sm-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-sm-l-35 {
            margin-left: auto;
            margin-right: 35px;
        }

        .mg-sm-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-sm-l-40 {
            margin-left: auto;
            margin-right: 40px;
        }

        .mg-sm-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-sm-l-45 {
            margin-left: auto;
            margin-right: 45px;
        }

        .mg-sm-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-sm-l-50 {
            margin-left: auto;
            margin-right: 50px;
        }

        .mg-sm-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-sm-l-55 {
            margin-left: auto;
            margin-right: 55px;
        }

        .mg-sm-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-sm-l-60 {
            margin-left: auto;
            margin-right: 60px;
        }

        .mg-sm-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-sm-l-65 {
            margin-left: auto;
            margin-right: 65px;
        }

        .mg-sm-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-sm-l-70 {
            margin-left: auto;
            margin-right: 70px;
        }

        .mg-sm-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-sm-l-75 {
            margin-left: auto;
            margin-right: 75px;
        }

        .mg-sm-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-sm-l-80 {
            margin-left: auto;
            margin-right: 80px;
        }

        .mg-sm-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-sm-l-85 {
            margin-left: auto;
            margin-right: 85px;
        }

        .mg-sm-r-90 {
            margin-right: auto;
            margin-left: 90px;
        }

        .mg-sm-l-90 {
            margin-left: auto;
            margin-right: 90px;
        }

        .mg-sm-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-sm-l-95 {
            margin-left: auto;
            margin-right: 95px;
        }

        .mg-sm-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-sm-l-100 {
            margin-left: auto;
            margin-right: 100px;
        }

        .mg-sm-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-sm-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 768px) {
        .mg-md-r-0 {
            margin-left: 0;
            margin-right: auto;
        }

        .mg-md-l-0 {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-md-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-md-l-1 {
            margin-left: auto;
            margin-right: 1px;
        }

        .mg-md-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-md-l-2 {
            margin-left: auto;
            margin-right: 2px;
        }

        .mg-md-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-md-l-3 {
            margin-left: auto;
            margin-right: 3px;
        }

        .mg-md-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-md-l-4 {
            margin-left: auto;
            margin-right: 4px;
        }

        .mg-md-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-md-l-5 {
            margin-left: auto;
            margin-right: 5px;
        }

        .mg-md-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-md-l-6 {
            margin-left: auto;
            margin-right: 6px;
        }

        .mg-md-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-md-l-7 {
            margin-left: auto;
            margin-right: 7px;
        }

        .mg-md-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-md-l-8 {
            margin-left: auto;
            margin-right: 8px;
        }

        .mg-md-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-md-l-9 {
            margin-left: auto;
            margin-right: 9px;
        }

        .mg-md-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-md-l-10 {
            margin-left: auto;
            margin-right: 10px;
        }

        .mg-md-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-md-l-15 {
            margin-left: auto;
            margin-right: 15px;
        }

        .mg-md-r-20 {
            margin-right: 0;
            margin-left: 20px;
        }

        .mg-md-l-20 {
            margin-left: auto;
            margin-right: 20px;
        }

        .mg-md-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-md-l-25 {
            margin-left: auto;
            margin-right: 25px;
        }

        .mg-md-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-md-l-30 {
            margin-left: auto;
            margin-right: 30px;
        }

        .mg-md-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-md-l-35 {
            margin-left: auto;
            margin-right: 35px;
        }

        .mg-md-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-md-l-40 {
            margin-left: auto;
            margin-right: 40px;
        }

        .mg-md-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-md-l-45 {
            margin-left: auto;
            margin-right: 45px;
        }

        .mg-md-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-md-l-50 {
            margin-left: auto;
            margin-right: 50px;
        }

        .mg-md-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-md-l-55 {
            margin-left: auto;
            margin-right: 55px;
        }

        .mg-md-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-md-l-60 {
            margin-left: auto;
            margin-right: 60px;
        }

        .mg-md-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-md-l-65 {
            margin-left: auto;
            margin-right: 65px;
        }

        .mg-md-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-md-l-70 {
            margin-left: auto;
            margin-right: 70px;
        }

        .mg-md-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-md-l-75 {
            margin-left: auto;
            margin-right: 75px;
        }

        .mg-md-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-md-l-80 {
            margin-left: auto;
            margin-right: 80px;
        }

        .mg-md-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-md-l-85 {
            margin-left: auto;
            margin-right: 85px;
        }

        .mg-md-r-90 {
            margin-right: auto;
            margin-left: 90px;
        }

        .mg-md-l-90 {
            margin-left: auto;
            margin-right: 90px;
        }

        .mg-md-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-md-l-95 {
            margin-left: auto;
            margin-right: 95px;
        }

        .mg-md-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-md-l-100 {
            margin-left: auto;
            margin-right: 100px;
        }

        .mg-md-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-md-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 992px) {
        .mg-lg-r-0 {
            margin-left: 0;
            margin-right: auto;
        }

        .mg-lg-l-0 {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-lg-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-lg-l-1 {
            margin-left: auto;
            margin-right: 1px;
        }

        .mg-lg-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-lg-l-2 {
            margin-left: auto;
            margin-right: 2px;
        }

        .mg-lg-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-lg-l-3 {
            margin-left: auto;
            margin-right: 3px;
        }

        .mg-lg-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-lg-l-4 {
            margin-left: auto;
            margin-right: 4px;
        }

        .mg-lg-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-lg-l-5 {
            margin-left: auto;
            margin-right: 5px;
        }

        .mg-lg-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-lg-l-6 {
            margin-left: auto;
            margin-right: 6px;
        }

        .mg-lg-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-lg-l-7 {
            margin-left: auto;
            margin-right: 7px;
        }

        .mg-lg-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-lg-l-8 {
            margin-left: auto;
            margin-right: 8px;
        }

        .mg-lg-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-lg-l-9 {
            margin-left: auto;
            margin-right: 9px;
        }

        .mg-lg-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-lg-l-10 {
            margin-left: auto;
            margin-right: 10px;
        }

        .mg-lg-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-lg-l-15 {
            margin-left: auto;
            margin-right: 15px;
        }

        .mg-lg-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-lg-l-20 {
            margin-left: auto;
            margin-right: 20px;
        }

        .mg-lg-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-lg-l-25 {
            margin-left: auto;
            margin-right: 25px;
        }

        .mg-lg-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-lg-l-30 {
            margin-left: auto;
            margin-right: 30px;
        }

        .mg-lg-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-lg-l-35 {
            margin-left: auto;
            margin-right: 35px;
        }

        .mg-lg-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-lg-l-40 {
            margin-left: auto;
            margin-right: 40px;
        }

        .mg-lg-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-lg-l-45 {
            margin-left: auto;
            margin-right: 45px;
        }

        .mg-lg-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-lg-l-50 {
            margin-left: auto;
            margin-right: 50px;
        }

        .mg-lg-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-lg-l-55 {
            margin-left: auto;
            margin-right: 55px;
        }

        .mg-lg-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-lg-l-60 {
            margin-left: auto;
            margin-right: 60px;
        }

        .mg-lg-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-lg-l-65 {
            margin-left: auto;
            margin-right: 65px;
        }

        .mg-lg-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-lg-l-70 {
            margin-left: auto;
            margin-right: 70px;
        }

        .mg-lg-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-lg-l-75 {
            margin-left: auto;
            margin-right: 75px;
        }

        .mg-lg-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-lg-l-80 {
            margin-left: auto;
            margin-right: 80px;
        }

        .mg-lg-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-lg-l-85 {
            margin-left: auto;
            margin-right: 85px;
        }

        .mg-lg-r-90 {
            margin-right: auto;
            margin-left: 90px;
        }

        .mg-lg-l-90 {
            margin-left: auto;
            margin-right: 90px;
        }

        .mg-lg-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-lg-l-95 {
            margin-left: auto;
            margin-right: 95px;
        }

        .mg-lg-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-lg-l-100 {
            margin-left: auto;
            margin-right: 100px;
        }

        .mg-lg-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-lg-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @media (min-width: 1200px) {
        .mg-xl-r-0 {
            margin-left: 0;
            margin-right: auto;
        }

        .mg-xl-l-0 {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-xl-r-1 {
            margin-right: auto;
            margin-left: 1px;
        }

        .mg-xl-l-1 {
            margin-left: auto;
            margin-right: 1px;
        }

        .mg-xl-r-2 {
            margin-right: auto;
            margin-left: 2px;
        }

        .mg-xl-l-2 {
            margin-left: auto;
            margin-right: 2px;
        }

        .mg-xl-r-3 {
            margin-right: auto;
            margin-left: 3px;
        }

        .mg-xl-l-3 {
            margin-left: auto;
            margin-right: 3px;
        }

        .mg-xl-r-4 {
            margin-right: auto;
            margin-left: 4px;
        }

        .mg-xl-l-4 {
            margin-left: auto;
            margin-right: 4px;
        }

        .mg-xl-r-5 {
            margin-right: auto;
            margin-left: 5px;
        }

        .mg-xl-l-5 {
            margin-left: auto;
            margin-right: 5px;
        }

        .mg-xl-r-6 {
            margin-right: auto;
            margin-left: 6px;
        }

        .mg-xl-l-6 {
            margin-left: auto;
            margin-right: 6px;
        }

        .mg-xl-r-7 {
            margin-right: auto;
            margin-left: 7px;
        }

        .mg-xl-l-7 {
            margin-left: auto;
            margin-right: 7px;
        }

        .mg-xl-r-8 {
            margin-right: auto;
            margin-left: 8px;
        }

        .mg-xl-l-8 {
            margin-left: auto;
            margin-right: 8px;
        }

        .mg-xl-r-9 {
            margin-right: auto;
            margin-left: 9px;
        }

        .mg-xl-l-9 {
            margin-left: auto;
            margin-right: 9px;
        }

        .mg-xl-r-10 {
            margin-right: auto;
            margin-left: 10px;
        }

        .mg-xl-l-10 {
            margin-left: auto;
            margin-right: 10px;
        }

        .mg-xl-r-15 {
            margin-right: auto;
            margin-left: 15px;
        }

        .mg-xl-l-15 {
            margin-left: auto;
            margin-right: 15px;
        }

        .mg-xl-r-20 {
            margin-right: auto;
            margin-left: 20px;
        }

        .mg-xl-l-20 {
            margin-left: auto;
            margin-right: 20px;
        }

        .mg-xl-r-25 {
            margin-right: auto;
            margin-left: 25px;
        }

        .mg-xl-l-25 {
            margin-left: auto;
            margin-right: 25px;
        }

        .mg-xl-r-30 {
            margin-right: auto;
            margin-left: 30px;
        }

        .mg-xl-l-30 {
            margin-left: auto;
            margin-right: 30px;
        }

        .mg-xl-r-35 {
            margin-right: auto;
            margin-left: 35px;
        }

        .mg-xl-l-35 {
            margin-left: auto;
            margin-right: 35px;
        }

        .mg-xl-r-40 {
            margin-right: auto;
            margin-left: 40px;
        }

        .mg-xl-l-40 {
            margin-left: auto;
            margin-right: 40px;
        }

        .mg-xl-r-45 {
            margin-right: auto;
            margin-left: 45px;
        }

        .mg-xl-l-45 {
            margin-left: auto;
            margin-right: 45px;
        }

        .mg-xl-r-50 {
            margin-right: auto;
            margin-left: 50px;
        }

        .mg-xl-l-50 {
            margin-left: auto;
            margin-right: 50px;
        }

        .mg-xl-r-55 {
            margin-right: auto;
            margin-left: 55px;
        }

        .mg-xl-l-55 {
            margin-left: auto;
            margin-right: 55px;
        }

        .mg-xl-r-60 {
            margin-right: auto;
            margin-left: 60px;
        }

        .mg-xl-l-60 {
            margin-left: auto;
            margin-right: 60px;
        }

        .mg-xl-r-65 {
            margin-right: auto;
            margin-left: 65px;
        }

        .mg-xl-l-65 {
            margin-left: auto;
            margin-right: 65px;
        }

        .mg-xl-r-70 {
            margin-right: auto;
            margin-left: 70px;
        }

        .mg-xl-l-70 {
            margin-left: auto;
            margin-right: 70px;
        }

        .mg-xl-r-75 {
            margin-right: auto;
            margin-left: 75px;
        }

        .mg-xl-l-75 {
            margin-left: auto;
            margin-right: 75px;
        }

        .mg-xl-r-80 {
            margin-right: auto;
            margin-left: 80px;
        }

        .mg-xl-l-80 {
            margin-left: auto;
            margin-right: 80px;
        }

        .mg-xl-r-85 {
            margin-right: auto;
            margin-left: 85px;
        }

        .mg-xl-l-85 {
            margin-left: auto;
            margin-right: 85px;
        }

        .mg-xl-r-90 {
            margin-right: auto;
            margin-left: 90px;
        }

        .mg-xl-l-90 {
            margin-left: auto;
            margin-right: 90px;
        }

        .mg-xl-r-95 {
            margin-right: auto;
            margin-left: 95px;
        }

        .mg-xl-l-95 {
            margin-left: auto;
            margin-right: 95px;
        }

        .mg-xl-r-100 {
            margin-right: auto;
            margin-left: 100px;
        }

        .mg-xl-l-100 {
            margin-left: auto;
            margin-right: 100px;
        }

        .mg-xl-r-auto {
            margin-right: 0;
            margin-left: auto;
        }

        .mg-xl-l-auto {
            margin-left: 0;
            margin-right: auto;
        }
    }

    .me-0 {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    .ms-0 {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .me-1 {
        margin-right: auto !important;
        margin-left: 0.25rem !important;
    }

    .ms-1 {
        margin-left: auto !important;
        margin-right: 0.25rem !important;
    }

    .me-2 {
        margin-right: auto !important;
        margin-left: 0.5rem !important;
    }

    .ms-2 {
        margin-left: auto !important;
        margin-right: 0.5rem !important;
    }

    .me-3 {
        margin-right: auto !important;
        margin-left: 1rem !important;
    }

    .ms-3 {
        margin-left: auto !important;
        margin-right: 1rem !important;
    }

    .me-4 {
        margin-right: auto !important;
        margin-left: 1.5rem !important;
    }

    .ms-4 {
        margin-left: auto !important;
        margin-right: 1.5rem !important;
    }

    .me-5 {
        margin-right: auto !important;
        margin-left: 3rem !important;
    }

    .ms-5 {
        margin-left: auto !important;
        margin-right: 3rem !important;
    }

    .pe-0 {
        padding-right: inherit !important;
        padding-left: 0 !important;
    }

    .ps-0 {
        padding-left: initial !important;
        padding-right: 0 !important;
    }

    .pe-1 {
        padding-right: inherit !important;
        padding-left: 0.25rem !important;
    }

    .ps-1 {
        padding-left: inherit !important;
        padding-right: 0.25rem !important;
    }

    .pe-2 {
        padding-right: inherit !important;
        padding-left: 0.5rem !important;
    }

    .ps-2 {
        padding-left: inherit !important;
        padding-right: 0.5rem !important;
    }

    .pe-3 {
        padding-right: inherit !important;
        padding-left: 1rem !important;
    }

    .ps-3 {
        padding-left: inherit !important;
        padding-right: 1rem !important;
    }

    .pe-4 {
        padding-right: inherit !important;
        padding-left: 1.5rem !important;
    }

    .ps-4 {
        padding-left: inherit !important;
        padding-right: 1.5rem !important;
    }

    .pe-5 {
        padding-right: inherit !important;
        padding-left: 3rem !important;
    }

    .ps-5 {
        padding-left: inherit !important;
        padding-right: 3rem !important;
    }

    .me-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .ms-auto {
        margin-left: 0 !important;
        margin-right: auto !important;
    }

    @media (min-width: 576px) {
        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: auto !important;
        }

        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .me-sm-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-sm-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-sm-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-sm-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-sm-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-sm-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-sm-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-sm-4 {
            margin-left: auto !important;
            margin-right: 1.5rem !important;
        }

        .me-sm-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-sm-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .pe-sm-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-sm-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-sm-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-sm-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-sm-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-sm-2 {
            padding-left: inherit !important;
            padding-right: 0.5rem !important;
        }

        .pe-sm-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-sm-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-sm-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-sm-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-sm-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-sm-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }

        .me-sm-n1 {
            margin-right: auto !important;
            margin-left: -0.25rem !important;
        }

        .ms-sm-n1 {
            margin-left: auto !important;
            margin-right: -0.25rem !important;
        }

        .me-sm-n2 {
            margin-right: auto !important;
            margin-left: -0.5rem !important;
        }

        .ms-sm-n2 {
            margin-left: auto !important;
            margin-right: -0.5rem !important;
        }

        .me-sm-n3 {
            margin-right: auto !important;
            margin-left: -1rem !important;
        }

        .ms-sm-n3 {
            margin-left: auto !important;
            margin-right: -1rem !important;
        }

        .me-sm-n4 {
            margin-right: auto !important;
            margin-left: -1.5rem !important;
        }

        .ms-sm-n4 {
            margin-left: auto !important;
            margin-right: -1.5rem !important;
        }

        .me-sm-n5 {
            margin-right: auto !important;
            margin-left: -3rem !important;
        }

        .ms-sm-n5 {
            margin-left: auto !important;
            margin-right: -3rem !important;
        }

        .me-sm-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-sm-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    @media (min-width: 768px) {
        .me-md-0 {
            margin-right: auto !important;
            margin-left: 0 !important;
        }

        .ms-md-0 {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .me-md-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-md-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-md-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-md-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-md-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-md-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-md-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-md-4 {
            margin-left: 0 !important;
            margin-right: 1.5rem !important;
        }

        .me-md-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-md-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .pe-md-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-md-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-md-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-md-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-md-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-md-2 {
            padding-left: inherit !important;
            padding-right: 0.5rem !important;
        }

        .pe-md-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-md-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-md-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-md-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-md-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-md-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }

        .me-md-n1 {
            margin-right: auto !important;
            margin-left: -0.25rem !important;
        }

        .ms-md-n1 {
            margin-left: auto !important;
            margin-right: -0.25rem !important;
        }

        .me-md-n2 {
            margin-right: auto !important;
            margin-left: -0.5rem !important;
        }

        .ms-md-n2 {
            margin-left: auto !important;
            margin-right: -0.5rem !important;
        }

        .me-md-n3 {
            margin-right: auto !important;
            margin-left: -1rem !important;
        }

        .ms-md-n3 {
            margin-left: auto !important;
            margin-right: -1rem !important;
        }

        .me-md-n4 {
            margin-right: auto !important;
            margin-left: -1.5rem !important;
        }

        .ms-md-n4 {
            margin-left: auto !important;
            margin-right: -1.5rem !important;
        }

        .me-md-n5 {
            margin-right: auto !important;
            margin-left: -3rem !important;
        }

        .ms-md-n5 {
            margin-left: auto !important;
            margin-right: -3rem !important;
        }

        .me-md-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-md-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-right: auto !important;
            margin-left: 0 !important;
        }

        .ms-lg-0 {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .me-lg-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-lg-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-lg-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-lg-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-lg-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-lg-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-lg-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-lg-4 {
            margin-left: auto !important;
            margin-right: 1.5rem !important;
        }

        .me-lg-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-lg-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .pe-lg-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-lg-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-lg-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-lg-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-lg-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-lg-2 {
            padding-left: inherit !important;
            padding-right: 0.5rem !important;
        }

        .pe-lg-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-lg-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-lg-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-lg-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-lg-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-lg-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }

        .me-lg-n1 {
            margin-right: auto !important;
            margin-left: -0.25rem !important;
        }

        .ms-lg-n1 {
            margin-left: auto !important;
            margin-right: -0.25rem !important;
        }

        .me-lg-n2 {
            margin-right: auto !important;
            margin-left: -0.5rem !important;
        }

        .ms-lg-n2 {
            margin-left: auto !important;
            margin-right: -0.5rem !important;
        }

        .me-lg-n3 {
            margin-right: auto !important;
            margin-left: -1rem !important;
        }

        .ms-lg-n3 {
            margin-left: auto !important;
            margin-right: -1rem !important;
        }

        .me-lg-n4 {
            margin-right: auto !important;
            margin-left: -1.5rem !important;
        }

        .ms-lg-n4 {
            margin-left: auto !important;
            margin-right: -1.5rem !important;
        }

        .me-lg-n5 {
            margin-right: auto !important;
            margin-left: -3rem !important;
        }

        .ms-lg-n5 {
            margin-left: auto !important;
            margin-right: -3rem !important;
        }

        .me-lg-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-lg-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    @media (min-width: 1200px) {
        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: auto !important;
        }

        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .me-xl-1 {
            margin-right: auto !important;
            margin-left: 0.25rem !important;
        }

        .ms-xl-1 {
            margin-left: auto !important;
            margin-right: 0.25rem !important;
        }

        .me-xl-2 {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .ms-xl-2 {
            margin-left: auto !important;
            margin-right: 0.5rem !important;
        }

        .me-xl-3 {
            margin-right: auto !important;
            margin-left: 1rem !important;
        }

        .ms-xl-3 {
            margin-left: auto !important;
            margin-right: 1rem !important;
        }

        .me-xl-4 {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }

        .ms-xl-4 {
            margin-left: auto !important;
            margin-right: 1.5rem !important;
        }

        .me-xl-5 {
            margin-right: auto !important;
            margin-left: 3rem !important;
        }

        .ms-xl-5 {
            margin-left: auto !important;
            margin-right: 3rem !important;
        }

        .pe-xl-0 {
            padding-right: inherit !important;
            padding-left: 0 !important;
        }

        .ps-xl-0 {
            padding-left: inherit !important;
            padding-right: 0 !important;
        }

        .pe-xl-1 {
            padding-right: inherit !important;
            padding-left: 0.25rem !important;
        }

        .ps-xl-1 {
            padding-left: inherit !important;
            padding-right: 0.25rem !important;
        }

        .pe-xl-2 {
            padding-right: inherit !important;
            padding-left: 0.5rem !important;
        }

        .ps-xl-2 {
            padding-left: inherit !important;
            padding-right: 0.5rem !important;
        }

        .pe-xl-3 {
            padding-right: inherit !important;
            padding-left: 1rem !important;
        }

        .ps-xl-3 {
            padding-left: inherit !important;
            padding-right: 1rem !important;
        }

        .pe-xl-4 {
            padding-right: inherit !important;
            padding-left: 1.5rem !important;
        }

        .ps-xl-4 {
            padding-left: inherit !important;
            padding-right: 1.5rem !important;
        }

        .pe-xl-5 {
            padding-right: inherit !important;
            padding-left: 3rem !important;
        }

        .ps-xl-5 {
            padding-left: inherit !important;
            padding-right: 3rem !important;
        }

        .me-xl-n1 {
            margin-right: auto !important;
            margin-left: -0.25rem !important;
        }

        .ms-xl-n1 {
            margin-left: auto !important;
            margin-right: -0.25rem !important;
        }

        .me-xl-n2 {
            margin-right: auto !important;
            margin-left: -0.5rem !important;
        }

        .ms-xl-n2 {
            margin-left: auto !important;
            margin-right: -0.5rem !important;
        }

        .me-xl-n3 {
            margin-right: auto !important;
            margin-left: -1rem !important;
        }

        .ms-xl-n3 {
            margin-left: auto !important;
            margin-right: -1rem !important;
        }

        .me-xl-n4 {
            margin-right: auto !important;
            margin-left: -1.5rem !important;
        }

        .ms-xl-n4 {
            margin-left: auto !important;
            margin-right: -1.5rem !important;
        }

        .me-xl-n5 {
            margin-right: auto !important;
            margin-left: -3rem !important;
        }

        .ms-xl-n5 {
            margin-left: auto !important;
            margin-right: -3rem !important;
        }

        .me-xl-auto {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .ms-xl-auto {
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    .pd-r-0 {
        padding-right: inherit;
        padding-left: 0px;
    }

    .pd-l-0 {
        padding-left: inherit;
        padding-right: 0px;
    }

    .pd-r-1 {
        padding-right: inherit;
        padding-left: 1px;
    }

    .pd-l-1 {
        padding-left: inherit;
        padding-right: 1px;
    }

    .pd-r-2 {
        padding-right: inherit;
        padding-left: 2px;
    }

    .pd-l-2 {
        padding-left: inherit;
        padding-right: 2px;
    }

    .pd-r-3 {
        padding-right: inherit;
        padding-left: 3px;
    }

    .pd-l-3 {
        padding-left: inherit;
        padding-right: 3px;
    }

    .pd-r-4 {
        padding-right: inherit;
        padding-left: 4px;
    }

    .pd-l-4 {
        padding-left: inherit;
        padding-right: 4px;
    }

    .pd-r-5 {
        padding-right: inherit;
        padding-left: 5px;
    }

    .pd-l-5 {
        padding-left: inherit;
        padding-right: 5px;
    }

    .pd-r-6 {
        padding-right: inherit;
        padding-left: 6px;
    }

    .pd-l-6 {
        padding-left: inherit;
        padding-right: 6px;
    }

    .pd-r-7 {
        padding-right: inherit;
        padding-left: 7px;
    }

    .pd-l-7 {
        padding-left: inherit;
        padding-right: 7px;
    }

    .pd-r-8 {
        padding-right: inherit;
        padding-left: 8px;
    }

    .pd-l-8 {
        padding-left: inherit;
        padding-right: 8px;
    }

    .pd-r-9 {
        padding-right: inherit;
        padding-left: 9px;
    }

    .pd-l-9 {
        padding-left: inherit;
        padding-right: 9px;
    }

    .pd-r-10 {
        padding-right: inherit;
        padding-left: 10px;
    }

    .pd-l-10 {
        padding-left: inherit;
        padding-right: 10px;
    }

    .pd-r-15 {
        padding-right: inherit;
        padding-left: 15px;
    }

    .pd-l-15 {
        padding-left: inherit;
        padding-right: 15px;
    }

    .pd-r-20 {
        padding-right: inherit;
        padding-left: 20px;
    }

    .pd-l-20 {
        padding-left: inherit;
        padding-right: 20px;
    }

    .pd-r-25 {
        padding-right: inherit;
        padding-left: 25px;
    }

    .pd-l-25 {
        padding-left: inherit;
        padding-right: 25px;
    }

    .pd-r-30 {
        padding-right: inherit;
        padding-left: 30px;
    }

    .pd-l-30 {
        padding-left: inherit;
        padding-right: 30px;
    }

    .pd-r-35 {
        padding-right: inherit;
        padding-left: 35px;
    }

    .pd-l-35 {
        padding-left: inherit;
        padding-right: 35px;
    }

    .pd-r-40 {
        padding-right: inherit;
        padding-left: 40px;
    }

    .pd-l-40 {
        padding-left: inherit;
        padding-right: 40px;
    }

    .pd-r-45 {
        padding-right: inherit;
        padding-left: 45px;
    }

    .pd-l-45 {
        padding-left: inherit;
        padding-right: 45px;
    }

    .pd-r-50 {
        padding-right: inherit;
        padding-left: 50px;
    }

    .pd-l-50 {
        padding-left: inherit;
        padding-right: 50px;
    }

    .pd-r-55 {
        padding-right: inherit;
        padding-left: 55px;
    }

    .pd-l-55 {
        padding-left: inherit;
        padding-right: 55px;
    }

    .pd-r-60 {
        padding-right: inherit;
        padding-left: 60px;
    }

    .pd-l-60 {
        padding-left: inherit;
        padding-right: 60px;
    }

    .pd-r-65 {
        padding-right: inherit;
        padding-left: 65px;
    }

    .pd-l-65 {
        padding-left: inherit;
        padding-right: 65px;
    }

    .pd-r-70 {
        padding-right: inherit;
        padding-left: 70px;
    }

    .pd-l-70 {
        padding-left: inherit;
        padding-right: 70px;
    }

    .pd-r-75 {
        padding-right: inherit;
        padding-left: 75px;
    }

    .pd-l-75 {
        padding-left: inherit;
        padding-right: 75px;
    }

    .pd-r-80 {
        padding-right: inherit;
        padding-left: 80px;
    }

    .pd-l-80 {
        padding-left: inherit;
        padding-right: 80px;
    }

    .pd-r-85 {
        padding-right: inherit;
        padding-left: 85px;
    }

    .pd-l-85 {
        padding-left: inherit;
        padding-right: 85px;
    }

    .pd-r-90 {
        padding-right: inherit;
        padding-left: 90px;
    }

    .pd-l-90 {
        padding-left: inherit;
        padding-right: 90px;
    }

    .pd-r-95 {
        padding-right: inherit;
        padding-left: 95px;
    }

    .pd-l-95 {
        padding-left: inherit;
        padding-right: 95px;
    }

    .pd-r-100 {
        padding-right: inherit;
        padding-left: 100px;
    }

    .pd-l-100 {
        padding-left: inherit;
        padding-right: 100px;
    }

    .pd-r-110 {
        padding-right: inherit;
        padding-left: 110px;
    }

    .pd-l-110 {
        padding-left: inherit;
        padding-right: 110px;
    }

    .pd-r-120 {
        padding-right: inherit;
        padding-left: 120px;
    }

    .pd-l-120 {
        padding-left: inherit;
        padding-right: 120px;
    }

    .pd-r-130 {
        padding-right: inherit;
        padding-left: 130px;
    }

    .pd-l-130 {
        padding-left: inherit;
        padding-right: 130px;
    }

    .pd-r-140 {
        padding-right: inherit;
        padding-left: 140px;
    }

    .pd-l-140 {
        padding-left: inherit;
        padding-right: 140px;
    }

    .pd-r-150 {
        padding-right: inherit;
        padding-left: 150px;
    }

    .pd-l-150 {
        padding-left: inherit;
        padding-right: 150px;
    }

    .pd-r-160 {
        padding-right: inherit;
        padding-left: 160px;
    }

    .pd-l-160 {
        padding-left: inherit;
        padding-right: 160px;
    }

    .pd-r-170 {
        padding-right: inherit;
        padding-left: 170px;
    }

    .pd-l-170 {
        padding-left: inherit;
        padding-right: 170px;
    }

    .pd-r-180 {
        padding-right: inherit;
        padding-left: 180px;
    }

    .pd-l-180 {
        padding-left: inherit;
        padding-right: 180px;
    }

    .pd-r-190 {
        padding-right: inherit;
        padding-left: 190px;
    }

    .pd-l-190 {
        padding-left: inherit;
        padding-right: 190px;
    }

    .pd-r-200 {
        padding-right: inherit;
        padding-left: 200px;
    }

    .pd-l-200 {
        padding-left: inherit;
        padding-right: 200px;
    }

    @media (min-width: 480px) {
        .pd-xs-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-xs-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-xs-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-xs-l-1 {
            padding-left: inherit;
            padding-right: 1px;
        }

        .pd-xs-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-xs-l-2 {
            padding-left: inherit;
            padding-right: 2px;
        }

        .pd-xs-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-xs-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-xs-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-xs-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-xs-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-xs-l-5 {
            padding-left: inherit;
            padding-right: 5px;
        }

        .pd-xs-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-xs-l-6 {
            padding-left: inherit;
            padding-right: 6px;
        }

        .pd-xs-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-xs-l-7 {
            padding-left: inherit;
            padding-right: 7px;
        }

        .pd-xs-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-xs-l-8 {
            padding-left: inherit;
            padding-right: 8px;
        }

        .pd-xs-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-xs-l-9 {
            padding-left: inherit;
            padding-right: 9px;
        }

        .pd-xs-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-xs-l-10 {
            padding-left: inherit;
            padding-right: 10px;
        }

        .pd-xs-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-xs-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-xs-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-xs-l-20 {
            padding-left: inherit;
            padding-right: 20px;
        }

        .pd-xs-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-xs-l-25 {
            padding-left: inherit;
            padding-right: 25px;
        }

        .pd-xs-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-xs-l-30 {
            padding-left: inherit;
            padding-right: 30px;
        }

        .pd-xs-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-xs-l-35 {
            padding-left: inherit;
            padding-right: 35px;
        }

        .pd-xs-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-xs-l-40 {
            padding-left: inherit;
            padding-right: 40px;
        }

        .pd-xs-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-xs-l-45 {
            padding-left: inherit;
            padding-right: 45px;
        }

        .pd-xs-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-xs-l-50 {
            padding-left: inherit;
            padding-right: 50px;
        }

        .pd-xs-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-xs-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-xs-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-xs-l-60 {
            padding-left: inherit;
            padding-right: 60px;
        }

        .pd-xs-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-xs-l-65 {
            padding-left: inherit;
            padding-right: 65px;
        }

        .pd-xs-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-xs-l-70 {
            padding-left: inherit;
            padding-right: 70px;
        }

        .pd-xs-r-75 {
            padding-right: inherit;
            padding-left: 75px;
        }

        .pd-xs-l-75 {
            padding-left: inherit;
            padding-right: 75px;
        }

        .pd-xs-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-xs-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-xs-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-xs-l-85 {
            padding-left: inherit;
            padding-right: 85px;
        }

        .pd-xs-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-xs-l-90 {
            padding-left: inherit;
            padding-right: 90px;
        }

        .pd-xs-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-xs-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-xs-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-xs-l-100 {
            padding-left: inherit;
            padding-right: 100px;
        }

        .pd-xs-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-xs-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-xs-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-xs-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-xs-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-xs-l-130 {
            padding-left: inherit;
            padding-right: 130px;
        }

        .pd-xs-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-xs-l-140 {
            padding-left: inherit;
            padding-right: 140px;
        }

        .pd-xs-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-xs-l-150 {
            padding-left: inherit;
            padding-right: 150px;
        }

        .pd-xs-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-xs-l-160 {
            padding-left: inherit;
            padding-right: 160px;
        }

        .pd-xs-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-xs-l-170 {
            padding-left: inherit;
            padding-right: 170px;
        }

        .pd-xs-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-xs-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-xs-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-xs-l-190 {
            padding-left: inherit;
            padding-right: 190px;
        }

        .pd-xs-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-xs-l-200 {
            padding-left: inherit;
            padding-right: 200px;
        }
    }

    @media (min-width: 576px) {
        .pd-sm-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-sm-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-sm-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-sm-l-1 {
            padding-left: inherit;
            padding-right: 1px;
        }

        .pd-sm-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-sm-l-2 {
            padding-left: inherit;
            padding-right: 2px;
        }

        .pd-sm-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-sm-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-sm-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-sm-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-sm-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-sm-l-5 {
            padding-left: inherit;
            padding-right: 5px;
        }

        .pd-sm-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-sm-l-6 {
            padding-left: inherit;
            padding-right: 6px;
        }

        .pd-sm-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-sm-l-7 {
            padding-left: inherit;
            padding-right: 7px;
        }

        .pd-sm-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-sm-l-8 {
            padding-left: inherit;
            padding-right: 8px;
        }

        .pd-sm-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-sm-l-9 {
            padding-left: inherit;
            padding-right: 9px;
        }

        .pd-sm-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-sm-l-10 {
            padding-left: inherit;
            padding-right: 10px;
        }

        .pd-sm-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-sm-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-sm-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-sm-l-20 {
            padding-left: inherit;
            padding-right: 20px;
        }

        .pd-sm-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-sm-l-25 {
            padding-left: inherit;
            padding-right: 25px;
        }

        .pd-sm-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-sm-l-30 {
            padding-left: inherit;
            padding-right: 30px;
        }

        .pd-sm-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-sm-l-35 {
            padding-left: inherit;
            padding-right: 35px;
        }

        .pd-sm-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-sm-l-40 {
            padding-left: inherit;
            padding-right: 40px;
        }

        .pd-sm-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-sm-l-45 {
            padding-left: inherit;
            padding-right: 45px;
        }

        .pd-sm-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-sm-l-50 {
            padding-left: inherit;
            padding-right: 50px;
        }

        .pd-sm-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-sm-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-sm-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-sm-l-60 {
            padding-left: inherit;
            padding-right: 60px;
        }

        .pd-sm-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-sm-l-65 {
            padding-left: inherit;
            padding-right: 65px;
        }

        .pd-sm-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-sm-l-70 {
            padding-left: inherit;
            padding-right: 70px;
        }

        .pd-sm-r-75 {
            padding-right: inherit;
            padding-left: 75px;
        }

        .pd-sm-l-75 {
            padding-left: inherit;
            padding-right: 75px;
        }

        .pd-sm-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-sm-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-sm-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-sm-l-85 {
            padding-left: inherit;
            padding-right: 85px;
        }

        .pd-sm-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-sm-l-90 {
            padding-left: inherit;
            padding-right: 90px;
        }

        .pd-sm-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-sm-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-sm-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-sm-l-100 {
            padding-left: inherit;
            padding-right: 100px;
        }

        .pd-sm-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-sm-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-sm-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-sm-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-sm-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-sm-l-130 {
            padding-left: inherit;
            padding-right: 130px;
        }

        .pd-sm-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-sm-l-140 {
            padding-left: inherit;
            padding-right: 140px;
        }

        .pd-sm-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-sm-l-150 {
            padding-left: inherit;
            padding-right: 150px;
        }

        .pd-sm-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-sm-l-160 {
            padding-left: inherit;
            padding-right: 160px;
        }

        .pd-sm-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-sm-l-170 {
            padding-left: inherit;
            padding-right: 170px;
        }

        .pd-sm-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-sm-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-sm-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-sm-l-190 {
            padding-left: inherit;
            padding-right: 190px;
        }

        .pd-sm-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-sm-l-200 {
            padding-left: inherit;
            padding-right: 200px;
        }
    }

    @media (min-width: 768px) {
        .pd-md-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-md-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-md-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-md-l-1 {
            padding-left: inherit;
            padding-right: 1px;
        }

        .pd-md-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-md-l-2 {
            padding-left: inherit;
            padding-right: 2px;
        }

        .pd-md-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-md-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-md-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-md-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-md-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-md-l-5 {
            padding-left: inherit;
            padding-right: 5px;
        }

        .pd-md-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-md-l-6 {
            padding-left: inherit;
            padding-right: 6px;
        }

        .pd-md-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-md-l-7 {
            padding-left: inherit;
            padding-right: 7px;
        }

        .pd-md-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-md-l-8 {
            padding-left: inherit;
            padding-right: 8px;
        }

        .pd-md-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-md-l-9 {
            padding-left: inherit;
            padding-right: 9px;
        }

        .pd-md-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-md-l-10 {
            padding-left: inherit;
            padding-right: 10px;
        }

        .pd-md-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-md-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-md-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-md-l-20 {
            padding-left: inherit;
            padding-right: 20px;
        }

        .pd-md-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-md-l-25 {
            padding-left: inherit;
            padding-right: 25px;
        }

        .pd-md-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-md-l-30 {
            padding-left: inherit;
            padding-right: 30px;
        }

        .pd-md-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-md-l-35 {
            padding-left: inherit;
            padding-right: 35px;
        }

        .pd-md-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-md-l-40 {
            padding-left: inherit;
            padding-right: 40px;
        }

        .pd-md-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-md-l-45 {
            padding-left: inherit;
            padding-right: 45px;
        }

        .pd-md-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-md-l-50 {
            padding-left: inherit;
            padding-right: 50px;
        }

        .pd-md-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-md-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-md-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-md-l-60 {
            padding-left: inherit;
            padding-right: 60px;
        }

        .pd-md-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-md-l-65 {
            padding-left: inherit;
            padding-right: 65px;
        }

        .pd-md-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-md-l-70 {
            padding-left: inherit;
            padding-right: 70px;
        }

        .pd-md-r-75 {
            padding-right: inherit;
            padding-left: 75px;
        }

        .pd-md-l-75 {
            padding-left: inherit;
            padding-right: 75px;
        }

        .pd-md-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-md-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-md-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-md-l-85 {
            padding-left: inherit;
            padding-right: 85px;
        }

        .pd-md-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-md-l-90 {
            padding-left: inherit;
            padding-right: 90px;
        }

        .pd-md-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-md-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-md-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-md-l-100 {
            padding-left: inherit;
            padding-right: 100px;
        }

        .pd-md-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-md-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-md-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-md-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-md-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-md-l-130 {
            padding-left: inherit;
            padding-right: 130px;
        }

        .pd-md-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-md-l-140 {
            padding-left: inherit;
            padding-right: 140px;
        }

        .pd-md-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-md-l-150 {
            padding-left: inherit;
            padding-right: 150px;
        }

        .pd-md-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-md-l-160 {
            padding-left: inherit;
            padding-right: 160px;
        }

        .pd-md-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-md-l-170 {
            padding-left: inherit;
            padding-right: 170px;
        }

        .pd-md-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-md-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-md-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-md-l-190 {
            padding-left: inherit;
            padding-right: 190px;
        }

        .pd-md-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-md-l-200 {
            padding-left: inherit;
            padding-right: 200px;
        }
    }

    @media (min-width: 992px) {
        .pd-lg-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-lg-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-lg-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-lg-l-1 {
            padding-left: inherit;
            padding-right: 1px;
        }

        .pd-lg-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-lg-l-2 {
            padding-left: inherit;
            padding-right: 2px;
        }

        .pd-lg-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-lg-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-lg-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-lg-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-lg-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-lg-l-5 {
            padding-left: inherit;
            padding-right: 5px;
        }

        .pd-lg-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-lg-l-6 {
            padding-left: inherit;
            padding-right: 6px;
        }

        .pd-lg-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-lg-l-7 {
            padding-left: inherit;
            padding-right: 7px;
        }

        .pd-lg-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-lg-l-8 {
            padding-left: inherit;
            padding-right: 8px;
        }

        .pd-lg-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-lg-l-9 {
            padding-left: inherit;
            padding-right: 9px;
        }

        .pd-lg-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-lg-l-10 {
            padding-left: inherit;
            padding-right: 10px;
        }

        .pd-lg-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-lg-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-lg-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-lg-l-20 {
            padding-left: inherit;
            padding-right: 20px;
        }

        .pd-lg-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-lg-l-25 {
            padding-left: inherit;
            padding-right: 25px;
        }

        .pd-lg-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-lg-l-30 {
            padding-left: inherit;
            padding-right: 30px;
        }

        .pd-lg-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-lg-l-35 {
            padding-left: inherit;
            padding-right: 35px;
        }

        .pd-lg-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-lg-l-40 {
            padding-left: inherit;
            padding-right: 40px;
        }

        .pd-lg-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-lg-l-45 {
            padding-left: inherit;
            padding-right: 45px;
        }

        .pd-lg-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-lg-l-50 {
            padding-left: inherit;
            padding-right: 50px;
        }

        .pd-lg-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-lg-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-lg-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-lg-l-60 {
            padding-left: inherit;
            padding-right: 60px;
        }

        .pd-lg-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-lg-l-65 {
            padding-left: inherit;
            padding-right: 65px;
        }

        .pd-lg-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-lg-l-70 {
            padding-left: inherit;
            padding-right: 70px;
        }

        .pd-lg-r-75 {
            padding-right: inherit;
            padding-left: 75px;
        }

        .pd-lg-l-75 {
            padding-left: inherit;
            padding-right: 75px;
        }

        .pd-lg-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-lg-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-lg-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-lg-l-85 {
            padding-left: inherit;
            padding-right: 85px;
        }

        .pd-lg-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-lg-l-90 {
            padding-left: inherit;
            padding-right: 90px;
        }

        .pd-lg-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-lg-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-lg-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-lg-l-100 {
            padding-left: inherit;
            padding-right: 100px;
        }

        .pd-lg-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-lg-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-lg-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-lg-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-lg-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-lg-l-130 {
            padding-left: inherit;
            padding-right: 130px;
        }

        .pd-lg-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-lg-l-140 {
            padding-left: inherit;
            padding-right: 140px;
        }

        .pd-lg-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-lg-l-150 {
            padding-left: inherit;
            padding-right: 150px;
        }

        .pd-lg-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-lg-l-160 {
            padding-left: inherit;
            padding-right: 160px;
        }

        .pd-lg-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-lg-l-170 {
            padding-left: inherit;
            padding-right: 170px;
        }

        .pd-lg-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-lg-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-lg-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-lg-l-190 {
            padding-left: inherit;
            padding-right: 190px;
        }

        .pd-lg-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-lg-l-200 {
            padding-left: inherit;
            padding-right: 200px;
        }
    }

    @media (min-width: 1200px) {
        .pd-xl-r-0 {
            padding-right: inherit;
            padding-left: 0px;
        }

        .pd-xl-l-0 {
            padding-left: inherit;
            padding-right: 0px;
        }

        .pd-xl-r-1 {
            padding-right: inherit;
            padding-left: 1px;
        }

        .pd-xl-l-1 {
            padding-left: inherit;
            padding-right: 1px;
        }

        .pd-xl-r-2 {
            padding-right: inherit;
            padding-left: 2px;
        }

        .pd-xl-l-2 {
            padding-left: inherit;
            padding-right: 2px;
        }

        .pd-xl-r-3 {
            padding-right: inherit;
            padding-left: 3px;
        }

        .pd-xl-l-3 {
            padding-left: inherit;
            padding-right: 3px;
        }

        .pd-xl-r-4 {
            padding-right: inherit;
            padding-left: 4px;
        }

        .pd-xl-l-4 {
            padding-left: inherit;
            padding-right: 4px;
        }

        .pd-xl-r-5 {
            padding-right: inherit;
            padding-left: 5px;
        }

        .pd-xl-l-5 {
            padding-left: inherit;
            padding-right: 5px;
        }

        .pd-xl-r-6 {
            padding-right: inherit;
            padding-left: 6px;
        }

        .pd-xl-l-6 {
            padding-left: inherit;
            padding-right: 6px;
        }

        .pd-xl-r-7 {
            padding-right: inherit;
            padding-left: 7px;
        }

        .pd-xl-l-7 {
            padding-left: inherit;
            padding-right: 7px;
        }

        .pd-xl-r-8 {
            padding-right: inherit;
            padding-left: 8px;
        }

        .pd-xl-l-8 {
            padding-left: inherit;
            padding-right: 8px;
        }

        .pd-xl-r-9 {
            padding-right: inherit;
            padding-left: 9px;
        }

        .pd-xl-l-9 {
            padding-left: inherit;
            padding-right: 9px;
        }

        .pd-xl-r-10 {
            padding-right: inherit;
            padding-left: 10px;
        }

        .pd-xl-l-10 {
            padding-left: inherit;
            padding-right: 10px;
        }

        .pd-xl-r-15 {
            padding-right: inherit;
            padding-left: 15px;
        }

        .pd-xl-l-15 {
            padding-left: inherit;
            padding-right: 15px;
        }

        .pd-xl-r-20 {
            padding-right: inherit;
            padding-left: 20px;
        }

        .pd-xl-l-20 {
            padding-left: inherit;
            padding-right: 20px;
        }

        .pd-xl-r-25 {
            padding-right: inherit;
            padding-left: 25px;
        }

        .pd-xl-l-25 {
            padding-left: inherit;
            padding-right: 25px;
        }

        .pd-xl-r-30 {
            padding-right: inherit;
            padding-left: 30px;
        }

        .pd-xl-l-30 {
            padding-left: inherit;
            padding-right: 30px;
        }

        .pd-xl-r-35 {
            padding-right: inherit;
            padding-left: 35px;
        }

        .pd-xl-l-35 {
            padding-left: inherit;
            padding-right: 35px;
        }

        .pd-xl-r-40 {
            padding-right: inherit;
            padding-left: 40px;
        }

        .pd-xl-l-40 {
            padding-left: inherit;
            padding-right: 40px;
        }

        .pd-xl-r-45 {
            padding-right: inherit;
            padding-left: 45px;
        }

        .pd-xl-l-45 {
            padding-left: inherit;
            padding-right: 45px;
        }

        .pd-xl-r-50 {
            padding-right: inherit;
            padding-left: 50px;
        }

        .pd-xl-l-50 {
            padding-left: inherit;
            padding-right: 50px;
        }

        .pd-xl-r-55 {
            padding-right: inherit;
            padding-left: 55px;
        }

        .pd-xl-l-55 {
            padding-left: inherit;
            padding-right: 55px;
        }

        .pd-xl-r-60 {
            padding-right: inherit;
            padding-left: 60px;
        }

        .pd-xl-l-60 {
            padding-left: inherit;
            padding-right: 60px;
        }

        .pd-xl-r-65 {
            padding-right: inherit;
            padding-left: 65px;
        }

        .pd-xl-l-65 {
            padding-left: inherit;
            padding-right: 65px;
        }

        .pd-xl-r-70 {
            padding-right: inherit;
            padding-left: 70px;
        }

        .pd-xl-l-70 {
            padding-left: inherit;
            padding-right: 70px;
        }

        .pd-xl-r-75 {
            padding-right: inherit;
            padding-left: 75px;
        }

        .pd-xl-l-75 {
            padding-left: inherit;
            padding-right: 75px;
        }

        .pd-xl-r-80 {
            padding-right: inherit;
            padding-left: 80px;
        }

        .pd-xl-l-80 {
            padding-left: inherit;
            padding-right: 80px;
        }

        .pd-xl-r-85 {
            padding-right: inherit;
            padding-left: 85px;
        }

        .pd-xl-l-85 {
            padding-left: inherit;
            padding-right: 85px;
        }

        .pd-xl-r-90 {
            padding-right: inherit;
            padding-left: 90px;
        }

        .pd-xl-l-90 {
            padding-left: inherit;
            padding-right: 90px;
        }

        .pd-xl-r-95 {
            padding-right: inherit;
            padding-left: 95px;
        }

        .pd-xl-l-95 {
            padding-left: inherit;
            padding-right: 95px;
        }

        .pd-xl-r-100 {
            padding-right: inherit;
            padding-left: 100px;
        }

        .pd-xl-l-100 {
            padding-left: inherit;
            padding-right: 100px;
        }

        .pd-xl-r-110 {
            padding-right: inherit;
            padding-left: 110px;
        }

        .pd-xl-l-110 {
            padding-left: inherit;
            padding-right: 110px;
        }

        .pd-xl-r-120 {
            padding-right: inherit;
            padding-left: 120px;
        }

        .pd-xl-l-120 {
            padding-left: inherit;
            padding-right: 120px;
        }

        .pd-xl-r-130 {
            padding-right: inherit;
            padding-left: 130px;
        }

        .pd-xl-l-130 {
            padding-left: inherit;
            padding-right: 130px;
        }

        .pd-xl-r-140 {
            padding-right: inherit;
            padding-left: 140px;
        }

        .pd-xl-l-140 {
            padding-left: inherit;
            padding-right: 140px;
        }

        .pd-xl-r-150 {
            padding-right: inherit;
            padding-left: 150px;
        }

        .pd-xl-l-150 {
            padding-left: inherit;
            padding-right: 150px;
        }

        .pd-xl-r-160 {
            padding-right: inherit;
            padding-left: 160px;
        }

        .pd-xl-l-160 {
            padding-left: inherit;
            padding-right: 160px;
        }

        .pd-xl-r-170 {
            padding-right: inherit;
            padding-left: 170px;
        }

        .pd-xl-l-170 {
            padding-left: inherit;
            padding-right: 170px;
        }

        .pd-xl-r-180 {
            padding-right: inherit;
            padding-left: 180px;
        }

        .pd-xl-l-180 {
            padding-left: inherit;
            padding-right: 180px;
        }

        .pd-xl-r-190 {
            padding-right: inherit;
            padding-left: 190px;
        }

        .pd-xl-l-190 {
            padding-left: inherit;
            padding-right: 190px;
        }

        .pd-xl-r-200 {
            padding-right: inherit;
            padding-left: 200px;
        }

        .pd-xl-l-200 {
            padding-left: inherit;
            padding-right: 200px;
        }
    }

    .pd-r-12 {
        padding-right: inherit;
        padding-left: 12px;
    }

    .pd-l-12 {
        padding-left: inherit;
        padding-right: 12px;
    }

    .pd-r-13 {
        padding-right: inherit;
        padding-left: 13px;
    }

    .pd-l-13 {
        padding-left: inherit;
        padding-right: 13px;
    }

    .r-0 {
        right: auto;
        left: 0px;
    }

    .l-0 {
        left: auto;
        right: 0px;
    }

    .r--0 {
        right: auto;
        left: 0px;
    }

    .l--0 {
        left: auto;
        right: 0px;
    }

    .r-5 {
        right: auto;
        left: 5px;
    }

    .l-5 {
        left: auto;
        left: 5px;
    }

    .r--5 {
        right: auto;
        left: -5px;
    }

    .l--5 {
        left: auto;
        right: -5px;
    }

    .r-10 {
        right: auto;
        left: 10px;
    }

    .l-10 {
        left: auto;
        right: 10px;
    }

    .r--10 {
        right: auto;
        left: -10px;
    }

    .l--10 {
        left: auto;
        right: -10px;
    }

    .r-15 {
        right: auto;
        left: 15px;
    }

    .l-15 {
        left: auto;
        right: 15px;
    }

    .r--15 {
        right: auto;
        left: -15px;
    }

    .l--15 {
        left: auto;
        right: -15px;
    }

    .r-20 {
        right: auto;
        left: 20px;
    }

    .l-20 {
        left: auto;
        right: 20px;
    }

    .r--20 {
        right: auto;
        left: -20px;
    }

    .l--20 {
        left: auto;
        right: -20px;
    }

    .r-25 {
        right: auto;
        left: 25px;
    }

    .l-25 {
        left: auto;
        right: 25px;
    }

    .r--25 {
        right: auto;
        left: -25px;
    }

    .l--25 {
        left: auto;
        right: -25px;
    }

    .r-30 {
        right: auto;
        left: 30px;
    }

    .l-30 {
        left: auto;
        right: 30px;
    }

    .r--30 {
        right: auto;
        left: -30px;
    }

    .l--30 {
        left: auto;
        right: -30px;
    }

    .r-35 {
        right: auto;
        left: 35px;
    }

    .l-35 {
        left: auto;
        right: 35px;
    }

    .r--35 {
        right: auto;
        left: -35px;
    }

    .l--35 {
        left: auto;
        right: -35px;
    }

    .r-40 {
        right: auto;
        left: 40px;
    }

    .l-40 {
        left: auto;
        right: 40px;
    }

    .r--40 {
        right: auto;
        left: -40px;
    }

    .l--40 {
        left: auto;
        right: -40px;
    }

    .r-45 {
        right: auto;
        left: 45px;
    }

    .l-45 {
        left: auto;
        right: 45px;
    }

    .r--45 {
        right: auto;
        left: -45px;
    }

    .l--45 {
        left: auto;
        right: -45px;
    }

    .r-50 {
        right: auto;
        left: 50px;
    }

    .l-50 {
        left: auto;
        right: 50px;
    }

    .r--50 {
        right: auto;
        left: -50px;
    }

    .l--50 {
        left: auto;
        right: -50px;
    }

    .r-55 {
        right: auto;
        left: 55px;
    }

    .l-55 {
        left: auto;
        right: 55px;
    }

    .r--55 {
        right: auto;
        left: -55px;
    }

    .l--55 {
        left: auto;
        right: -55px;
    }

    .r-60 {
        right: auto;
        left: 60px;
    }

    .l-60 {
        left: auto;
        right: 60px;
    }

    .r--60 {
        right: auto;
        left: -60px;
    }

    .l--60 {
        left: auto;
        right: -60px;
    }

    .r-65 {
        right: auto;
        left: 65px;
    }

    .l-65 {
        left: auto;
        right: 65px;
    }

    .r--65 {
        right: auto;
        left: -65px;
    }

    .l--65 {
        left: auto;
        right: -65px;
    }

    .r-70 {
        right: auto;
        left: 70px;
    }

    .l-70 {
        left: auto;
        right: 70px;
    }

    .r--70 {
        right: auto;
        left: -70px;
    }

    .l--70 {
        left: auto;
        right: -70px;
    }

    .r-75 {
        right: auto;
        left: 75px;
    }

    .l-75 {
        left: auto;
        right: 75px;
    }

    .r--75 {
        right: auto;
        left: -75px;
    }

    .l--75 {
        left: auto;
        right: -75px;
    }

    .r-80 {
        right: auto;
        left: 80px;
    }

    .l-80 {
        left: auto;
        right: 80px;
    }

    .r--80 {
        right: auto;
        left: -80px;
    }

    .l--80 {
        left: auto;
        right: -80px;
    }

    .r-85 {
        right: auto;
        left: 85px;
    }

    .l-85 {
        left: auto;
        right: 85px;
    }

    .r--85 {
        right: auto;
        left: -85px;
    }

    .l--85 {
        left: auto;
        right: -85px;
    }

    .r-90 {
        right: auto;
        left: 90px;
    }

    .l-90 {
        left: auto;
        right: 90px;
    }

    .r--90 {
        right: auto;
        left: -90px;
    }

    .l--90 {
        left: auto;
        right: -90px;
    }

    .r-95 {
        right: auto;
        left: 95px;
    }

    .l-95 {
        left: auto;
        right: 95px;
    }

    .r--95 {
        right: auto;
        left: -95px;
    }

    .l--95 {
        left: auto;
        right: -95px;
    }

    .r-100 {
        right: auto;
        left: 100px;
    }

    .l-100 {
        left: auto;
        right: 100px;
    }

    .r--100 {
        right: auto;
        left: -100px;
    }

    .l--100 {
        left: auto;
        right: -100px;
    }

    .r--5 {
        right: auto;
        left: -5px;
    }

    @media (min-width: 480px) {
        .r-xs-auto {
            right: 0;
            left: auto;
        }

        .l-xs-auto {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 576px) {
        .r-sm-auto {
            right: 0;
            left: auto;
        }

        .l-sm-auto {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 768px) {
        .r-md-auto {
            right: 0;
            left: auto;
        }

        .l-md-auto {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 992px) {
        .r-lg-auto {
            right: 0;
            left: auto;
        }

        .l-lg-auto {
            right: auto;
            left: 0;
        }
    }

    @media (min-width: 1200px) {
        .r-xl-auto {
            right: 0;
            left: auto;
        }

        .l-xl-auto {
            right: auto;
            left: 0;
        }
    }

    .tx-right {
        text-align: left;
    }

    .tx-left {
        text-align: right;
    }

    @media (min-width: 480px) {
        .tx-xs-right {
            text-align: left;
        }

        .tx-xs-left {
            text-align: right;
        }
    }

    @media (min-width: 576px) {
        .tx-sm-right {
            text-align: left;
        }

        .tx-sm-left {
            text-align: right;
        }
    }

    @media (min-width: 768px) {
        .tx-md-right {
            text-align: left;
        }

        .tx-md-left {
            text-align: right;
        }
    }

    @media (min-width: 992px) {
        .tx-lg-right {
            text-align: left;
        }

        .tx-lg-left {
            text-align: right;
        }
    }

    @media (min-width: 1200px) {
        .tx-xl-right {
            text-align: left;
        }

        .tx-xl-left {
            text-align: right;
        }
    }

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-left {
            text-align: right !important;
        }

        .text-sm-right {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-left {
            text-align: right !important;
        }

        .text-md-right {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-left {
            text-align: right !important;
        }

        .text-lg-right {
            text-align: left !important;
        }
    }

    @media (min-width: 1200px) {
        .text-xl-left {
            text-align: right !important;
        }

        .text-xl-right {
            text-align: left !important;
        }
    }

    .offset-1 {
        margin-left: auto;
        margin-right: 8.33333%;
    }

    .offset-2 {
        margin-left: auto;
        margin-right: 16.66667%;
    }

    .offset-3 {
        margin-left: auto;
        margin-right: 25%;
    }

    .offset-4 {
        margin-left: auto;
        margin-right: 33.33333%;
    }

    .offset-5 {
        margin-left: auto;
        margin-right: 41.66667%;
    }

    .offset-6 {
        margin-left: auto;
        margin-right: 50%;
    }

    .offset-7 {
        margin-left: auto;
        margin-right: 58.33333%;
    }

    .offset-8 {
        margin-left: auto;
        margin-right: 66.66667%;
    }

    .offset-9 {
        margin-left: auto;
        margin-right: 75%;
    }

    .offset-10 {
        margin-left: auto;
        margin-right: 83.33333%;
    }

    .offset-11 {
        margin-left: auto;
        margin-right: 91.66667%;
    }

    @media (min-width: 576px) {
        .offset-sm-0 {
            margin-left: auto;
            margin-right: 0;
        }

        .offset-sm-1 {
            margin-left: auto;
            margin-right: 8.33333%;
        }

        .offset-sm-2 {
            margin-left: auto;
            margin-right: 16.66667%;
        }

        .offset-sm-3 {
            margin-left: auto;
            margin-right: 25%;
        }

        .offset-sm-4 {
            margin-left: auto;
            margin-right: 33.33333%;
        }

        .offset-sm-5 {
            margin-left: auto;
            margin-right: 41.66667%;
        }

        .offset-sm-6 {
            margin-left: auto;
            margin-right: 50%;
        }

        .offset-sm-7 {
            margin-left: auto;
            margin-right: 58.33333%;
        }

        .offset-sm-8 {
            margin-left: auto;
            margin-right: 66.66667%;
        }

        .offset-sm-9 {
            margin-left: auto;
            margin-right: 75%;
        }

        .offset-sm-10 {
            margin-left: auto;
            margin-right: 83.33333%;
        }

        .offset-sm-11 {
            margin-left: auto;
            margin-right: 91.66667%;
        }
    }

    @media (min-width: 768px) {
        .offset-md-0 {
            margin-left: auto;
            margin-right: 0;
        }

        .offset-md-1 {
            margin-left: auto;
            margin-right: 8.33333%;
        }

        .offset-md-2 {
            margin-left: auto;
            margin-right: 16.66667%;
        }

        .offset-md-3 {
            margin-left: auto;
            margin-right: 25%;
        }

        .offset-md-4 {
            margin-left: auto;
            margin-right: 33.33333%;
        }

        .offset-md-5 {
            margin-left: auto;
            margin-right: 41.66667%;
        }

        .offset-md-6 {
            margin-left: auto;
            margin-right: 50%;
        }

        .offset-md-7 {
            margin-left: auto;
            margin-right: 58.33333%;
        }

        .offset-md-8 {
            margin-left: auto;
            margin-right: 66.66667%;
        }

        .offset-md-9 {
            margin-left: auto;
            margin-right: 75%;
        }

        .offset-md-10 {
            margin-left: auto;
            margin-right: 83.33333%;
        }

        .offset-md-11 {
            margin-left: auto;
            margin-right: 91.66667%;
        }
    }

    @media (min-width: 992px) {
        .offset-lg-0 {
            margin-left: auto;
            margin-right: 0;
        }

        .offset-lg-1 {
            margin-left: auto;
            margin-right: 8.33333%;
        }

        .offset-lg-2 {
            margin-left: auto;
            margin-right: 16.66667%;
        }

        .offset-lg-3 {
            margin-left: auto;
            margin-right: 25%;
        }

        .offset-lg-4 {
            margin-left: auto;
            margin-right: 33.33333%;
        }

        .offset-lg-5 {
            margin-left: auto;
            margin-right: 41.66667%;
        }

        .offset-lg-6 {
            margin-left: auto;
            margin-right: 50%;
        }

        .offset-lg-7 {
            margin-left: auto;
            margin-right: 58.33333%;
        }

        .offset-lg-8 {
            margin-left: auto;
            margin-right: 66.66667%;
        }

        .offset-lg-9 {
            margin-left: auto;
            margin-right: 75%;
        }

        .offset-lg-10 {
            margin-left: auto;
            margin-right: 83.33333%;
        }

        .offset-lg-11 {
            margin-left: auto;
            margin-right: 91.66667%;
        }
    }

    @media (min-width: 1200px) {
        .offset-xl-0 {
            margin-left: auto;
            margin-right: 0;
        }

        .offset-xl-1 {
            margin-left: auto;
            margin-right: 8.33333%;
        }

        .offset-xl-2 {
            margin-left: auto;
            margin-right: 16.66667%;
        }

        .offset-xl-3 {
            margin-left: auto;
            margin-right: 25%;
        }

        .offset-xl-4 {
            margin-left: auto;
            margin-right: 33.33333%;
        }

        .offset-xl-5 {
            margin-left: auto;
            margin-right: 41.66667%;
        }

        .offset-xl-6 {
            margin-left: auto;
            margin-right: 50%;
        }

        .offset-xl-7 {
            margin-left: auto;
            margin-right: 58.33333%;
        }

        .offset-xl-8 {
            margin-left: auto;
            margin-right: 66.66667%;
        }

        .offset-xl-9 {
            margin-left: auto;
            margin-right: 75%;
        }

        .offset-xl-10 {
            margin-left: auto;
            margin-right: 83.33333%;
        }

        .offset-xl-11 {
            margin-left: auto;
            margin-right: 91.66667%;
        }
    }

    @media (min-width: 1600px) {
        .offset-xxl-0 {
            margin-left: auto;
            margin-right: 0;
        }

        .offset-xxl-1 {
            margin-left: auto;
            margin-right: 8.33333%;
        }

        .offset-xxl-2 {
            margin-left: auto;
            margin-right: 16.66667%;
        }

        .offset-xxl-3 {
            margin-left: auto;
            margin-right: 25%;
        }

        .offset-xxl-4 {
            margin-left: auto;
            margin-right: 33.33333%;
        }

        .offset-xxl-5 {
            margin-left: auto;
            margin-right: 41.66667%;
        }

        .offset-xxl-6 {
            margin-left: auto;
            margin-right: 50%;
        }

        .offset-xxl-7 {
            margin-left: auto;
            margin-right: 58.33333%;
        }

        .offset-xxl-8 {
            margin-left: auto;
            margin-right: 66.66667%;
        }

        .offset-xxl-9 {
            margin-left: auto;
            margin-right: 75%;
        }

        .offset-xxl-10 {
            margin-left: auto;
            margin-right: 83.33333%;
        }

        .offset-xxl-11 {
            margin-left: auto;
            margin-right: 91.66667%;
        }
    }

    .slick-track {
        left: auto;
        right: 0;
    }

    .slick-slide {
        float: right;
    }

    [dir=rtl] .slick-slide {
        float: left;
    }

    .tag-addon {
        margin: 0 0.5rem 0 -0.5rem;

        &:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    .tag-avatar {
        margin: 0 -0.5rem 0 0.5rem;
    }

    .tags>.tag:not(:last-child) {
        margin-right: auto;
        margin-left: 0.5rem;
    }

    .nav-tabs .nav-link+.nav-link {
        margin-left: 0;
        margin-right: 3px;
    }

    .main-nav-tabs {
        .lSSlideOuter {
            padding-left: 35px;
            padding-right: 32px;
        }

        .lSAction>a {
            &.lSPrev {
                left: auto;
                right: -32px;
                transform: rotate(180deg);
            }

            &.lSNext {
                right: auto;
                left: -35px;
                transform: rotate(180deg);
            }
        }
    }

    .tabs-style-2 .main-nav-line .nav-link {
        margin: 0 0 0px 5px;
    }

    .tabs-style-3 .nav.panel-tabs li a {
        margin: 0 0 5px 5px;
    }

    // .nav {
    //     padding-left: inherit;
    //     padding-right: 0;
    // }

    @media (min-width: 992px) {
        .main-navbar {
            .nav-sub {
                left: auto;
                right: 0;
            }

            .nav-sub-item {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .main-navbar {
        left: auto;
        right: 0;

        .nav-sub-link:before {
            left: auto;
            right: -19px;
            transform: rotate(180deg);
        }

        .nav-sub-link.sub-with-sub::after {
            transform: rotate(180deg);
        }
    }

    @media (min-width: 992px) {
        .main-navbar {
            left: 0;
            right: auto;
            border-left: 0;
        }
    }

    @media (min-width: 992px) {
        .main-navbar {
            .nav-item {
                padding-right: inherit;
                padding-left: 28px;
            }

            .nav-link {
                i {
                    margin-right: auto;
                    margin-left: 10px;
                }

                &.with-sub {
                    &::after {
                        margin-left: 0;
                        margin-right: auto;
                    }

                    &::before {
                        left: auto;
                        right: 31%;
                        margin-left: auto;
                        margin-right: -7px;
                        border-left-color: transparent;
                        border-right-color: rgba(61, 119, 180, 0.2);
                    }
                }
            }

            .nav-sub-item>.nav-sub {
                left: auto;
                right: 159px;
            }

            .nav-sub-link.with-sub::after {
                margin-left: 0;
                margin-right: auto;
            }

            .nav-sub-mega .nav li:not(.nav-sub-item) {
                margin-left: auto;
                margin-right: 30px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-item+.nav-item {
            padding-left: inherit;
            padding-right: 0;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link i {
            margin-right: auto;
            margin-left: 8px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-link.with-sub::after {
            margin-left: auto;
            margin-right: 4px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1477px) {
        .main-navbar .nav-sub-item>.nav-sub {
            left: auto !important;
            right: -200px !important;
        }
    }

    @media (max-width: 991.98px) {
        .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item {
            margin-left: auto;
            margin-right: 37px;

            >.nav-sub-link {
                padding-left: inherit;
                padding-right: 10px;
            }
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-link {
            margin-left: 0;
            margin-right: 18px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-link.with-sub::after {
            margin-left: 0;
            margin-right: 4px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega {

            .container>div+div,
            .container-fluid>div+div {
                border-left: 0;
                border-right: 1px solid $border;
            }
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-sub-mega .nav+.nav {
            margin-left: auto;
            margin-right: 20px;
        }
    }

    @media (min-width: 1200px) {
        .main-navbar .nav-sub-mega .nav+.nav {
            margin-left: auto;
            margin-right: 25px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .main-navbar-search {

        .btn,
        .sp-container button {
            right: auto;
            left: 0;
        }
    }

    .sp-container .main-navbar-search button {
        right: auto;
        left: 0;
    }

    .main-navbar-two .nav-sub-item>.nav-sub {
        left: auto;
        right: 178px;
    }

    .main-navbar-three {
        .nav-item+.nav-item {
            border-left-width: 1px;
            border-right-width: 0;
            padding-left: inherit;
            padding-right: 0;
        }

        .nav-sub-item .nav-sub {
            left: auto;
            right: 182px;
        }
    }

    @media (min-width: 992px) {
        .main-navbar-three .nav-item+.nav-item {
            margin-left: auto;
            margin-right: 25px;
        }
    }

    .navbar {
        &.navbar-static .navbar-header {
            float: right;
        }

        .navbar-toggle {
            &.toggle-left {
                float: right;
                margin-left: auto;
                margin-right: 15px;
            }

            &.toggle-right {
                float: left;
                margin-right: auto;
                margin-left: 15px;
            }
        }
    }

    .wizard {
        >.steps>ul li {

            .title,
            +li {
                margin-left: 0;
                margin-right: 5px;
            }
        }

        &.vertical>.steps ul li+li {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    @media (min-width: 576px) {
        .wizard>.steps>ul li .title {
            margin-left: 0;
            margin-right: 10px;
        }

        .wizard>.steps>ul li+li {
            margin-left: 0;
            margin-right: 20px;
        }

        .wizard.vertical>.steps {
            float: right;
        }

        .wizard.vertical>.steps ul li+li {
            margin-left: auto;
            margin-right: 0;
        }

        .wizard.vertical>.content {
            float: left;
            border-left: 0;
            border-right: 1px solid $border;
        }

        .wizard.vertical>.actions {
            float: left;
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    @media (min-width: 992px) {
        .wizard>.steps>ul li+li {
            margin-left: 0;
            margin-right: 30px;
        }
    }

    .wizard-style-2>.steps>ul a {

        .title,
        &:hover .title,
        &:active .title {
            margin-right: auto;
            margin-left: 20px;
        }
    }

    .ps {
        >.ps__rail-y {
            right: auto !important;
            left: 0 !important;

            >.ps__thumb-y {
                left: auto;
                right: 1px;
            }
        }

        &.ps--active-y {

            &:hover>.ps__rail-y,
            &:focus>.ps__rail-y {
                right: auto !important;
                left: -1px !important;
            }
        }
    }

    .rdiobox span {
        padding-left: inherit;
        padding-right: 13px;

        &:before {
            left: auto;
            right: 0;
        }

        &:after {
            left: auto;
            right: 5px;
        }

        &:empty {
            padding-left: inherit;
            padding-right: 0;
        }
    }

    .form-group-rdiobox .rdiobox {
        +.rdiobox {
            margin-left: auto;
            margin-right: 30px;
        }

        span {
            padding-left: inherit;
            padding-right: 0;
        }
    }

    .custom-select {

        &[multiple],
        &[size]:not([size="1"]) {
            padding-right: inherit;
            padding-left: 0.75rem;
        }
    }

    .custom-select-sm {
        padding-left: inherit;
        padding-right: 0.5rem;
    }

    .custom-select-lg {
        padding-left: inherit;
        padding-right: 1rem;
    }

    .custom-file-label::after {
        right: auto;
        left: 0;
        border-left: 1px solid $border;
        border-right: inherit;
    }

    .custom-range::-ms-fill-upper {
        margin-right: auto;
        margin-left: 15px;
    }

    .custom-switch-indicator:before {
        left: auto;
        right: 1px;
    }

    .custom-switch-description {
        margin-left: auto;
        margin-right: 0.5rem;
    }

    .custom-switch {

        .custom-control-label {
            &::before {
                left: auto;
                right: -2.25rem;
            }

            &::after {
                left: auto;
                right: calc(-2.25rem + 2px);
            }
        }
    }

    .jq-star-svg {
        padding-left: inherit;
        padding-right: 3px;
    }

    .latest-timeline1-icon {
        left: auto;
        right: -38px;
    }

    .latest-timeline ul.timeline {
        &:before {
            left: auto;
            right: 15px;
        }

        >li {
            padding-left: inherit;
            padding-right: 1rem;
        }
    }

    .vtimeline {
        &:before {
            left: auto;
            right: 50%;
            margin-left: auto;
            margin-right: -1.5px;
        }

        .timeline-wrapper {
            padding-right: inherit;
            padding-left: 90px;

            .timeline-panel {
                margin-left: auto;
                margin-right: 15%;

                &:after {
                    right: auto;
                    left: -14px;
                    border-left: 0 solid #cad4e4;
                    border-right: 14px solid #cad4e4;
                }
            }

            .timeline-badge {
                left: auto;
                right: calc(50% - 7px);
            }

            &.timeline-inverted {
                padding-right: 90px;
                padding-left: 0;

                .timeline-panel {
                    margin-left: 15%;
                    margin-right: auto;

                    &:after {
                        border-left-width: 14px;
                        border-right-width: 0;
                        right: -14px;
                        left: auto;
                    }
                }
            }
        }
    }

    .img-sm {
        margin-right: 0;
        margin-left: 20px;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-right: 0;
        margin-left: 0.5rem;
    }

    .main-avatar::after {
        right: auto;
        left: 3px;
    }

    .avatar-md::after {
        right: auto;
        left: 2px;
    }

    .avatar-lg::after {
        right: auto;
        left: 4px;
    }

    .avatar-xl::after {
        right: auto;
        left: 5px;
    }

    .avatar-xxl::after {
        right: auto;
        left: 7px;
    }

    .demo-avatar-group {
        .main-img-user+ {

            .main-img-user,
            .main-avatar {
                margin-left: 0;
                margin-right: 5px;
            }
        }

        .main-avatar+ {

            .main-img-user,
            .main-avatar {
                margin-left: 0;
                margin-right: -5px;
            }
        }
    }

    @media (min-width: 576px) {
        .demo-avatar-group {
            .main-img-user+ {

                .main-img-user,
                .main-avatar {
                    margin-left: 0;
                    margin-right: -9px;
                }
            }

            .main-avatar+ {

                .main-img-user,
                .main-avatar {
                    margin-left: 0;
                    margin-right: -9px;
                }
            }
        }
    }

    @media (min-width: 576px) {
        #shapes .demo-avatar-group .main-img-user+ {

            .main-img-user,
            .main-avatar {
                margin-left: 0;
                margin-right: 9px;
            }
        }
    }

    .main-list-item>div:last-child {
        text-align: left;
    }

    .ckbox span {
        padding-left: inherit;
        padding-right: 16px;

        &:empty {
            float: right;
            padding-left: inherit;
            padding-right: 0;
        }

        &:before {
            left: auto;
            right: 0;
        }

        &:after {
            left: auto;
            right: 0;
        }
    }

    .main-toggle {
        span {
            left: auto;
            right: 3px;

            &::before {
                content: "on";
                left: auto;
                right: -25px;
            }

            &::after {
                right: auto;
                left: -29px;
            }
        }

        &.on span {
            left: auto;
            right: 37px;
        }
    }

    .list-unstyled,
    .list-inline {
        padding-left: 0;
        padding-right: 0;
    }

    .list-group-horizontal .list-group-item {
        margin-right: auto;
        margin-left: -1px;

        &:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &:last-child {
            margin-right: auto;
            margin-left: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
        }
    }

    @media (min-width: 576px) {
        .list-group-horizontal-sm .list-group-item {
            margin-right: auto;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    @media (min-width: 768px) {
        .list-group-horizontal-md .list-group-item {
            margin-right: auto;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    @media (min-width: 992px) {
        .list-group-horizontal-lg .list-group-item {
            margin-right: auto;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    @media (min-width: 1200px) {
        .list-group-horizontal-xl .list-group-item {
            margin-right: auto;
            margin-left: -1px;

            &:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:last-child {
                margin-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
            }
        }
    }

    .custom-control {
        padding-left: inherit;
        padding-right: 1.5rem;
    }

    .custom-control-inline {
        margin-right: auto;
        margin-left: 1rem;
    }

    .custom-control-label {

        &::before,
        &::after {
            left: auto;
            right: -1.5rem;
        }
    }

    .custom-control-label-md {
        padding-left: inherit;
        padding-right: 8px;
    }

    .custom-control-label-lg {
        padding-left: inherit;
        padding-right: 15px;
    }

    .custom-switch-input:checked~ {
        .custom-switch-indicator-md:before {
            left: auto;
            right: calc(1.46rem - 1px);
        }

        .custom-switch-indicator-lg:before {
            left: auto;
            right: calc(1.46rem + 1px);
        }
    }

    .embed-responsive {

        .embed-responsive-item,
        iframe,
        embed,
        object,
        video {
            left: auto;
            right: 0;
        }
    }

    .float-start {
        float: right !important;
    }

    .float-end {
        float: left !important;
    }

    @media (min-width: 576px) {
        .float-sm-left {
            float: right !important;
        }

        .float-sm-right {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-left {
            float: right !important;
        }

        .float-md-right {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-left {
            float: right !important;
        }

        .float-lg-right {
            float: left !important;
        }
    }

    @media (min-width: 1200px) {
        .float-xl-left {
            float: right !important;
        }

        .float-xl-right {
            float: left !important;
        }
    }

    &.main-body::before {
        left: auto;
        right: 70%;
    }

    .main-notification-list .media-body {
        margin-left: auto;
        margin-right: 15px;
    }

    .main-toggle-group-demo .main-toggle+.main-toggle {
        margin-left: 0;
        margin-right: 10px;
    }

    .main-content-left-components {
        border-right: 0;
        border-left: 1px solid $border;
    }

    .legend {
        margin-right: 0;
        margin-left: 10px;
    }

    .best-emp {
        right: auto;
        left: 0;
    }

    .userlist-table .dot-label {
        margin-right: 0 !important;
        margin-left: 10px !important;
    }

    @media (max-width: 768px) {
        .nav.main-nav-line.main-nav-line-chat.card-body .nav-link+.nav-link {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media (max-width: 576px) {
        .project-work.list-unstyled li {
            margin-right: auto;
            margin-left: 1rem;
        }
    }

    .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
        border-right: 1px solid $border;
        border-left: 0;
    }

    @media (max-width: 991px) {
        .header-search .select2-container--default .select2-selection--single {
            border-right: 1px solid $border;
            border-left: 0;
        }
    }

    .page-header .breadcrumb {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    @media (max-width: 991px) {
        .header-search .select2-container--default .select2-selection--single .select2-selection__arrow b {
            left: auto !important;
            right: 93% !important;
        }

        .main-logo .header-brand-img {
            margin-right: auto;
            margin-left: 10px;
        }

        .main-content .page-header .btn.btn-list {
            padding-left: inherit;
            padding-right: 0;
            text-align: right;
        }
    }
    @media (max-width: 991px) {
        .header-search.dropdown {
            .searchable__controls {
                border-radius: 0 3px 3px 0;
            }
        }
    }

    @media (max-width: 767px) {
        div {
            &.dataTables_wrapper div.dataTables_filter input {
                margin-left: auto !important;
                margin-right: 0 !important;
            }

            &.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
                padding-right: inherit;
                padding-left: 0;
            }
        }

        .vtimeline {
            &:before {
                left: auto !important;
                right: 6px !important;
            }

            .timeline-wrapper {
                padding-right: inherit !important;
                padding-left: 0 !important;

                .timeline-badge {
                    left: auto !important;
                    right: 0 !important;
                }

                .timeline-panel {
                    margin-left: auto !important;
                    margin-right: 10% !important;

                    &:after {
                        border-right: 0 solid #cad4e4 !important;
                        border-left: 14px solid #cad4e4 !important;
                        left: auto !important;
                        right: -14px !important;
                    }
                }

                &.timeline-inverted {
                    padding-left: inherit !important;
                    padding-right: 0 !important;

                    .timeline-panel {
                        margin-left: auto !important;
                        margin-right: 10% !important;
                    }
                }
            }
        }
    }

    @media (max-width: 575.98px) {
        .modal.pos-static .modal-footer> :not(:first-child) {
            margin-left: auto;
            margin-right: 0;
        }
    }

    @media (min-width: 992px) {
        &.horizontalmenu .main-footer {
            padding-left: inherit;
            padding-right: 0;
        }
    }

    #back-to-top {
        right: auto;
        left: 30px;
    }

    .custom-leftnav .main-content-left-components {
        border-right: 0;
        border-left: 0;
    }

    .component-item a:before {
        margin-right: auto;
        margin-left: 8px;
        float: right;
        transform: rotate(180deg);
    }

    .skill-tags a {
        float: right;
        margin-right: auto;
        margin-left: 5px;
    }

    .reviewnavs {
        padding-left: inherit;
        padding-right: 0;

        li {
            margin-right: auto;
            margin-left: 5px;
        }
    }

    .dash-icon {
        right: auto;
        left: 10px;
    }

    .price-value1 span {
        margin-left: auto;
        margin-right: 17px;
    }

    .clipboard-icon {
        right: auto;
        left: 10px;
    }

    .our-team .picture::after {
        left: auto;
        right: 0;
    }

    .task-list {
        &:after {
            left: auto;
            right: 30px;
        }

        li {
            padding-left: inherit;
            padding-right: 55px;

            .task-icon {
                left: auto;
                right: 22px;
            }
        }
    }

    .dot-label {
        margin-right: auto;
        margin-left: 5px;
    }

    .activity-block .task-list {
        li {
            padding-left: inherit;
            padding-right: 3rem;
        }

        &:after {
            left: auto;
            right: 18px;
        }

        li .task-icon {
            left: auto;
            right: 1px;
        }
    }

    .user-info {
        padding-left: inherit;
        padding-right: 0px;
    }

    .page-header .page-title i {
        margin-right: auto !important;
        margin-left: 8px !important;
    }

    .search-imgs li {
        margin-right: auto;
        margin-left: 1rem;

        img {
            margin-right: auto;
            margin-left: 1rem;
        }
    }

    .handle-counter {

        .counter-minus,
        .counter-plus,
        input {
            float: right;
        }
    }

    .itemside .info {
        padding-right: 15px;
        padding-left: 7px;
    }

    .project-status {
        &:before {
            margin-right: auto;
            margin-left: 12px;
        }

        &.success:before {
            border-left: 0;
            border-right: 3px solid #19b159;
        }

        &.danger:before {
            border-left: 0;
            border-right: 3px solid #f16d75;
        }

        &.warning:before {
            border-left: 0;
            border-right: 3px solid #ecb529;
        }
    }

    @media (max-width: 699px) {
        .breadcrumb-5 a:first-child {
            padding-left: inherit !important;
            padding-right: 16px !important;
        }
    }

    .selectgroup-item {
        +.selectgroup-item {
            margin-left: auto;
            margin-right: -1px;
        }

        &:not(:first-child) .selectgroup-button {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:not(:last-child) .selectgroup-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    .selectgroup-input {
        left: auto;
        right: 0;
    }

    .selectgroup-pills .selectgroup-item {
        margin-right: auto;
        margin-left: 0.5rem;
    }

    .colorinput {
        margin-right: auto;
        margin-left: 5px;
    }

    .colorinput-color:before {
        left: auto;
        right: 0.25rem;
    }

    .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: 0;
        margin-right: 0px;
    }

    .alert .btn-close {
        right: auto;
        left: 15px;
    }

    .form-select {
        padding: 0.175rem 0.75rem 0.175rem 2.25rem;
    }

    .card-img-left {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }

    .tags .tag-files span {
        margin-right: auto;
        margin-left: 104px;
        left: auto;
        right: 51px;
    }

    .image-pic {
        padding: 0px 10px 20px 0;
    }

    .pagination-radius .page-item+.page-item {
        margin-left: 0;
        margin-right: 5px;
    }

    #slide-right {
        left: 20px;
        right: auto;
    }

    #slide-left {
        right: 20px;
        left: auto;
    }

    .main-header-center .form-control .select2-container--default .select2-selection--single {
        border-right: 1px solid $border;
        border-left: 0;
    }

    .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
    .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-right: -1px;
        margin-left: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .main-header-center .select2-container--default .select2-selection--single {
        border-radius: 0 6px 6px 0;
    }

    .sidebar.sidebar-right,
    .sidebar.sidebar-profile {
        transform: translate(-100%, 0);
    }

    .main-sidebar-body .nav-item.active .nav-link:before {
        border-radius: 0px 0px 0px 48px;
    }

    .main-sidebar-body .nav-item.active .nav-link:after {
        border-radius: 48px 0px 0px 0px;
    }

    .main-sidebar-body {
        .angle {
            transform: rotate(180deg);
        }

        .nav-sub .nav-sub-link:before {
            transform: rotate(180deg);
        }
    }

    @media (max-width: 991.98px) {

        &.horizontalmenu .hor-menu i.angle {
            transform: rotate(180deg);
        }
    }

    @media (min-width: 992px) {
        &.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before {
            border-radius: 0px 0px 0px 48px;
        }

        &.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
            border-radius: 48px 0px 0px 0px;
        }
    }

    @media (max-width: 991px) {

        .main-sidebar,
        .main-navbar {
            transform: translateX(240px);
        }

    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: auto;
        left: 9px;
    }

    .main-header-center .select2-container--default .select2-selection--single {
        border-right: 1px solid $border;
        border-left: 0;
    }

    .main-header-center .btn {
        border-radius: 6px 0 0 6px !important;
    }

    @media (max-width: 991.98px) {

        &.horizontalmenu .main-body-1 li.nav-item.active .nav-link {
            border-radius: 0 30px 30px 0;
        }

        &.horizontalmenu .main-body-1 .nav-item.active .nav-link:before {
            border-radius: 0px 0px 0px 48px;
        }

        &.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
            border-radius: 48px 0px 0px 0px;
        }
    }

    @media (min-width: 992px) {

        .hor-menu .slide-left,
        .hor-menu .slide-right {
            transform: rotate(180deg);
        }

    }

    .btn-icon-text {
        i {
            float: right;
            margin-top: 4px;
        }
    }

    @media screen and (min-width: 992px) {
        &.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
            border-radius: 11px 0 0 11px !important;
        }

        &.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
            border-radius: 11px 0 0 11px !important;
        }
    }

    .tasks {
        .ckbox.me-4 {
            margin-right: 0 !important;
        }

        .me-2 {
            margin-right: 0 !important;
        }
    }

    .crypto-buysell-card .select2-container--default .select2-selection--single {
        border-radius: 6px 0 0 6px !important;
    }

    .crypto-wallet .clipboard-icon {
        border-radius: 4px 0 0 4px !important;
    }

    .crypto-cur,
    .crypto-cur2,
    .wallet {
        .me-3 {
            margin-right: 0 !important;
        }
    }

    .crypto-icon {
        &.me-3 {
            margin-right: 0 !important;
        }
    }

    .wallet .clipboard-icon {
        border-radius: 4px 0 0 4px !important;
    }

    .wallet .input-group-addon-left {
        border-radius: 0 4px 4px 0 !important;
    }

    .transcation-crypto {
        .me-2 {
            margin-right: 0 !important;
        }
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: left;
    }

    div.dataTables_wrapper div.dataTables_length label {
        text-align: right;
    }

    .page-item:last-child .page-link {
        border-radius: 6px 0 0 6px;
    }

    .page-item:first-child .page-link {
        border-radius: 0 6px 6px 0;
    }

    .table-product {
        .me-3 {
            margin-right: 0 !important;
            margin-left: 1rem !important;
        }
    }

    .input-group .btn.ripple.btn-primary {
        border-radius: 6px 0 0 6px !important;
    }

    .product-carousel {
        #carousel {
            .btn-list {
                a {
                    i {

                        margin-left: 5px;
                        float: right;
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    .btn-apply.coupon {
        border-radius: 4px 0 0 4px !important;
    }

    .counter-minus {
        border-radius: 0 3px 3px 0;
    }

    .counter-plus {
        border-radius: 3px 0 0 3px;
    }

    #wishlist {
        .product-grid {
            .btn {
                i {
                    display: inline-block;
                }
            }
        }
    }

    .ql-snow .ql-formats {
        float: right;
    }

    .ql-editor {
        text-align: right;
    }

    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
        margin-left: 0.5em;
    }

    .ql-snow .ql-tooltip {
        right: 19rem !important;
        left: auto !important;
    }

    .item1-links.nav.nav-tabs {
        .nav-link {
            i {
                float: right;
            }
        }
    }

    #myaddres {

        .btn-round,
        .card-header {
            i {
                float: right;
            }
        }
    }

    [type=email],
    [type=number],
    [type=tel],
    [type=url] {
        direction: rtl;
    }


    .inbox-pagination a.np-btn {
        transform: rotate(180deg);
    }

    .item-card-desc,
    .ad-post-details {
        .me-2 {
            margin-right: 0 !important;
        }

        .me-4 {
            margin-right: 0 !important;
        }
    }

    .alert-inner--icon {
        i {
            float: right;
            margin-top: 4px;
            margin-left: 10px;
        }
    }

    .breadcrumb-5 a {
        padding: 0 39px 0 10px;
    }

    .breadcrumb-5 a:last-child {
        border-radius: 5px 0 0 5px;
    }

    #icon {
        .btn {
            i {
                float: right;
                margin-top: 3px;
                margin-left: 5px;
            }
        }
    }

    .list-group.checked {
        i {
            float: right;
        }
    }

    .page-link i {
        transform: rotate(180deg);
    }

    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
    .bs-popover-start>.popover-arrow {
        right: calc(-0.5rem - 1px);
        left: auto;
    }

    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
    .bs-popover-start>.popover-arrow::after {
        left: 1px;
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: $white;
        border-right-color: transparent;
    }

    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
    .bs-popover-start>.popover-arrow::before {
        left: 0;
        right: auto;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, .25);
        border-right-color: transparent;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
    .bs-popover-end>.popover-arrow {
        left: calc(-0.5rem - 1px);
        right: auto;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
    .bs-popover-end>.popover-arrow::before {
        left: 0;
        right: auto;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, .25);
        border-left-color: transparent;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
    .bs-popover-end>.popover-arrow::after {
        left: 1px;
        right: auto;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: $white;
        border-left-color: transparent;
    }

    // .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
    // .bs-tooltip-start .tooltip-arrow {
    //     right: 0;
    //     left: auto;
    // }

    // .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
    // .bs-tooltip-start .tooltip-arrow::before {
    //     right: -1px;
    //     left: auto;
    //     border-width: 0.4rem 0 0.4rem 0.4rem;
    //     border-left-color: $black;
    // }

    // .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
    // .bs-tooltip-end .tooltip-arrow {
    //     left: 0;
    //     right: auto;
    // }

    // .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
    // .bs-tooltip-end .tooltip-arrow::before {
    //     right: -1px;
    //     left: auto;
    //     border-width: 0.4rem 0.4rem 0.4rem 0;
    //     border-right-color: $black;
    // }

    .tooltip-danger.bs-tooltip-right .arrow::before, .tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
        border-left-color: #f16d75 !important;
    }

    .tooltip-info.bs-tooltip-left .arrow::before, .tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
        border-right-color: #01b8ff !important;
    }

    .toast-header {
        .ms-2 {
            margin-left: 0 !important;
        }
    }

    #ChatCalls,
    #ChatContacts {
        .me-2 {
            margin-right: 0 !important;
            display: inline-block;
        }
    }

    .bootstrap-tagsinput .badge [data-role=remove] {
        margin-left: auto;
        margin-right: 8px;
    }

    .active.carousel-item-start,
    .carousel-item-prev:not(.carousel-item-end) {
        -webkit-transform: translateX(-100%);
        transform: translateX(100%) !important;
    }

    .active.carousel-item-end,
    .carousel-item-next:not(.carousel-item-start) {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%) !important;
    }

    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(-100%) !important;
    }

    .MS-content {
        direction: ltr;
    }

    .main-msg-wrapper:before,
    .main-msg-wrapper:after {
        border-color: transparent transparent transparent #f4f6ff !important;
    }

    .main-img-user {
        &.me-3 {
            margin-right: 0 !important;
        }
    }

    #external-events {
        text-align: right;
    }

    .fc .fc-scroller-harness {
        direction: rtl;
    }

    .fc .fc-button .fc-icon {
        transform: rotate(180deg);
    }

    .datepicker-container {
        direction: rtl;
    }

    .datepicker-panel>ul>li {
        float: right;
    }

    .timeline-footer {
        .me-1 {
            margin-right: 0 !important;
        }
    }

    #ui_notifIt {
        right: auto;
        left: 10px;
    }



    #notifIt_close {
        right: auto;
        left: 0;
    }



    .notifit_confirm_bg,
    .notifit_prompt_bg {
        left: auto;
        right: 0;
    }

    .notifit_confirm,
    .notifit_prompt {
        left: auto;
        right: 0;
    }

    .notifit_confirm_accept,
    .notifit_confirm_cancel,
    .notifit_prompt_accept,
    .notifit_prompt_cancel {
        margin-right: 0;
        margin-left: 5px;
    }

    .tree ul {
        margin-left: auto;
        margin-right: 1em;
    }

    .tree ul ul {
        margin-left: auto;
        margin-right: 0.5em;
    }

    .tree ul:before {
        left: auto;
        right: -10px;
        border-left: 0;
        border-right: 2px solid #d8e0f7;
    }


    .tree ul li:before {
        left: auto;
        right: -10px;
    }

    .tree li i {
        margin-right: auto;
        margin-left: 10px;
    }

    .tree {
        .branch {
            i {
                float: right;
                margin-top: 5px;
            }
        }
    }

    .dropdown-menu {
        .dropdown-item {
            i {
                float: right;
            }
        }
    }

    .settings-main-icon.me-4 {
        margin-right: 0 !important;
    }

    .file-browser .btn-primary {
        border-radius: 6px 0 0 6px !important;
    }

    .intl-tel-input .flag-dropdown .selected-flag {
        margin: 1px 1px 1px 2px;
        padding: 11px 6px 10px 16px;
    }

    .intl-tel-input .flag-dropdown .country-list .flag {
        margin-right: auto;
        margin-left: 6px;
    }

    .intl-tel-input .flag-dropdown .country-list .country-name {
        margin-right: auto;
        margin-left: 6px;
    }

    .intl-tel-input input {
        padding-left: 0;
        padding-right: 47px;
    }

    .telephone-input .btn {
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }


    .richText .richText-toolbar ul {
        padding-left: inherit;
        padding-right: 0;
    }

    .richText .richText-toolbar ul li {
        float: right;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
        right: auto;
        left: 10px;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
        float: right;
    }

    .richText .richText-editor {
        border-left: 0;
        border-right: $white solid 2px;
    }

    .richText .richText-editor:focus {
        border-left: $border solid 2px;
        border-right: transparent solid 2px;
    }

    .richText .richText-help {
        float: left;
    }

    .richText .richText-undo,
    .richText .richText-redo {
        float: left;
        border-right: 0;
        border-left: $border solid 1px;
    }

    .richText .richText-list.list-rightclick {
        border-right: 0;
        border-left: $border solid 1px;
    }

    .richText .richText-toolbar ul li a {
        border-right: 0;
        border-left: $border solid 1px;
    }

    @media screen and (min-width: 992px) {
        &.icontext-menu.main-sidebar-hide.main-sidebar-open li.nav-item.active .nav-link {
            border-radius: 0 30px 30px 0;
        }
    }

    .dropdown-menu .main-form-search .btn,
    .dropdown-menu .main-form-search .sp-container button {
        border-radius: 6px 0 0 6px;
    }

    .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
        border-radius: 0 3px 3px 0;
    }

    .transcation-crypto .table {

        th,
        td {
            &:last-child {
                border-left: 1px solid $border  !important;
            }
        }
    }

    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable td:first-child {
        border-right-width: 0px;
        border-left-width: 1px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control {
        padding-left: inherit;
        padding-right: 30px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        left: auto;
        right: 5px;
    }

    .side-menu .nav-item.show .nav-sub .nav-sub-item i.angle {
        transform: rotate(180deg);
    }

    .side-menu .nav-item .nav-sub .nav-sub-item.show i.angle {
        transform: rotate(90deg);
    }

    .side-menu .nav-item .nav-sub .nav-sub-item i.angle {
        right: auto;
        left: 20px;
    }

    .flag-dropdown {
        .dropdown-menu {
            .me-3 {
                margin-right: 0 !important;
            }
        }
    }

    table.dataTable>thead .sorting:after,
    table.dataTable>thead .sorting_asc:after,
    table.dataTable>thead .sorting_desc:after,
    table.dataTable>thead .sorting_asc_disabled:after,
    table.dataTable>thead .sorting_desc_disabled:after {
        left: 0.5em;
        right: auto;
    }

    table.dataTable>thead .sorting:before,
    table.dataTable>thead .sorting_asc:before,
    table.dataTable>thead .sorting_desc:before,
    table.dataTable>thead .sorting_asc_disabled:before,
    table.dataTable>thead .sorting_desc_disabled:before {
        left: 1em;
        right: auto;
    }

    .lg-toolbar .lg-icon {
        float: left;
    }

    #lg-counter {
        padding-left: inherit;
        padding-right: 20px;
    }

    .datetimepicker table th.prev span,
    .datetimepicker table th.next span {
        transform: rotate(180deg);
    }

    @media (max-width: 400px) {

        .datepicker-dropdown {
            left: 0 !important;
        }
    }

    .item-card-desc {
        a {
            display: inline-block;
        }
    }
    .main-content-body-profile{
        .me-2 {
            margin-right: 0!important;
        }
    }
    .table-product .me-3, .blog-details .me-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    .hor-scroll div:first-child {
        margin-left: -3px;
        margin-right: 0 !important;
    }
    .msl-actions {
      left: 5px;
      right: auto !important;      
    }
    .searchable__controls {
        padding-left: 34px;
        padding-right: 0 !important;
    }
    .searchable__controls__arrow__inner {
        right: 50%;
        left: inherit !important;
        transform: translate(50%, -50%);
    }
    .searchable__controls__arrow {
        left: 0;
        right: auto !important;
    }
    .searchable--active .searchable__controls__arrow__inner {
        transform: translate(50%, -50%) rotate(-180deg);
    }
    @media (min-width: 576px) {
        .float-sm-start {
            float: right !important;
        }

        .float-sm-end {
            float: left !important;
        }
    }
    @media (min-width: 768px) {
        .float-md-start {
            float: right !important;
        }

        .float-md-end {
            float: left !important;
        }
    }
    @media (min-width: 992px) {
        .float-lg-start {
            float: right !important;
        }

        .float-lg-end {
            float: left !important;
        }
    }
    @media (min-width: 1200px) {
        .float-xl-start {
            float: right !important;
        }

        .float-xl-end {
            float: left !important;
        }
    }
    .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content{
        margin-right: 8px;
        margin-left: 0;
    }
    .toast-header .btn-close {
        margin-left: -0.375rem;
        margin-right: 0.75rem;
    }
    .go1186250972 button {
        padding: 0 10px 0 0 !important;
    }
    .css-1eomvmf-MuiButtonBase-root-MuiIconButton-root {
        margin-right: auto;
        margin-left: inherit;
    }
    .contact-info {
        .me-1 {
            margin-right: inherit !important;
            margin-left: 0.25rem !important;
        }
        .me-0 {
            margin-right: inherit !important;
            margin-left: 0 !important;
        }
    }
    #carouselExample2,#carouselExample6 {
        .slick-prev {
            right: 0px;
            left: inherit;
        }
        .slick-next {
            left: 0px;
            right: inherit;
        }
    }
    .modal-header .btn-close {
        margin: -0.5rem auto -0.5rem -0.5rem;
    }
    .modal-body .react-datepicker-popper {
        right: 0px !important;
        left: auto !important;
        transform: translate(-38px, 90px) !important;
    }
    .deni-react-treeview-item-container.level-2 {
        padding-right: 30px !important;
        padding-left: 0 !important;
    }
    .deni-react-treeview-item-container.level-3 {
        padding-right: 65px !important;
        padding-left: 0 !important;
    } 
    .deni-react-treeview-item-container.level-4 {
        padding-right: 95px !important;
        padding-left: 0 !important;
    }
    .deni-react-treeview-item-container.level-5 {
        padding-right: 125px !important;
        padding-left: 0 !important;
    }
    .deni-react-treeview-item-container.level-6 {
        padding-right: 155px !important;
        padding-left: 0 !important;
    }
    .form-check.form-switch .form-check-label {
        margin-right: 36px;
        margin-left: 0;
    }
    .input-group-text input[type=radio],
    .input-group-text input[type=checkbox] {
        margin-right: -5px;
        margin-left: 0;
    }
    .react-datepicker-wrapper .form-control {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }
    .intl-tel-input.allow-dropdown input,
    .intl-tel-input.allow-dropdown input[type=text],
    .intl-tel-input.allow-dropdown input[type=tel] {
        padding-left: 6px;
        padding-right: 52px;
        margin-right: 0;
        margin-left: 0;
    }
    .intl-tel-input .country-list {
        text-align: right;
    }
    .MuiStepContent-root {
        border-right: 1px solid $border  !important;
        border-left: 0  !important;
        margin-right: 12px;
        margin-left: 0;
        padding-right: 20px;
        padding-left: 8px;
    }
    &.dark-theme .MuiStepContent-root {
        border-right: 1px solid $dark-border  !important;
        border-left: 0 !important;
    }
    .MuiStepConnector-line {
        border-right: 1px solid $border !important;
        border-left: 0 !important;
    }
    &.dark-theme .MuiStepConnector-line {
        border-right: 1px solid $dark-border  !important;
        border-left: 0 !important;
    }
    .MuiStepConnector-root {
        margin-right: 12px;
        margin-left: 0;
    }
    .public-DraftStyleDefault-ltr {
        direction: rtl;
        text-align: right;
    }
    .rdw-dropdown-carettoopen,.rdw-dropdown-carettoclose {
        left: 10%;
        right: auto;
    }
    .main-header.side-header .main-header-center .searchable__controls {
        border-radius: 0 5px 5px 0;
    }
    .currency-item {
        flex-direction: row-reverse;
    }
    .msl-chip-delete {
        margin-right: 3px;
        margin-left: 0;
    }
    .msl-chip {
        padding: 3px 6px 3px 3px;
    }
    &.landing-page .top-footer h6::before {
        right: -3px;
        left: auto;
    }
    .list-group {
        padding-right: 0;
        padding-left: 0;
    }
    .page-item:not(:first-child) .page-link {
        margin-right: -1px;
        margin-left: inherit;
    }
    .main-content.side-content {
        transition: margin-right 0.3s ease;
    }
    .intl-tel-input .flag-container .arrow {
        margin-right: 5px;
        margin-left: 0;
    }
    .chartsh.c3 {
        .c3-legend-item text {
            text-anchor: end;
        }
    }
    .apexcharts-yaxis-texts-g {
        text {
            text-anchor: start;
        }
    }

    .nvd3.nv-axis text { 
        text-anchor: start !important;
    }

    .nvd3 .nv-legend-text { 
        text-anchor: end;
    }

    .crypto-buysell-card .searchable__controls {
        border-radius: 5px 0 0 5px;
    }
    .ql-snow .ql-tooltip.ql-editing {
        right: 0 !important;
        left: inherit !important;
    }

    @media (max-width: 1199px) {
        .popover.bs-popover-end {
            transform: translate3d(0px, -95px, 0px) !important;
        }
        .popover.bs-popover-start {
            transform: translate3d(0px, -95px, 0px) !important;
        }
    }
}                                               